import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../queries/constants";
import getDeliveryOptions from "../../services/mfa/getDeliveryOptions";
import getDeliveryOptionsByIndId from "../../services/mfa/getDeliveryOptionsByIndId";

const useDeliveryOptions = (indId?: string, dbName?: string) => {
    let queryPayload = {
        queryKey: [QUERY_KEYS.GET_DELIVERY_OPTIONS],
        queryFn: getDeliveryOptions
    };
    if (indId && dbName) {
        queryPayload = {
            queryKey: [QUERY_KEYS.GET_DELIVERY_OPTIONS, indId, dbName],
            queryFn: () => getDeliveryOptionsByIndId({ indId, dbName })
        };
    }
    return useQuery(queryPayload);
};

export default useDeliveryOptions;
