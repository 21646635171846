import ReferenceData from "../../constants/ReferenceData";
import { executePost } from "../../utils/appUtils";

interface CreateAndDeliverActivationCodePayload {
    accu: string | undefined;
    deliveryOption: string;
}

interface CreateAndDeliverActivationCodeResponseData {
    activationCode: string | null;
    activationCodeId: string;
    dbName: string;
    finalStatusInd: string | null;
    indId: string;
    initialStatusInd: string | null;
    messageDeliveryCatStatusCode: string | null;
    messageDeliveryStatusCode: string;
    messageDeliveryTypeCode: string;
    messageDeliveryVendorCode: string;
    usrRegId: string;
    vendorInterfaceStatusText: string | null;
}

export type CreateAndDeliverActivationCodeSuccessResponse =
    | CreateAndDeliverActivationCodeResponseData
    | undefined;

const createAndDeliverActivationCode = (payload: CreateAndDeliverActivationCodePayload) => {
    const createAndDeliverActivationCodeSuccess = (
        results
    ): CreateAndDeliverActivationCodeSuccessResponse => {
        if (results?.data) {
            return results.data;
        }
    };

    return executePost(
        ReferenceData.CREATE_AND_DELIVER_ACTIVATION_CODE,
        "createAndDeliverActivationCodeFault",
        payload
    )
        .then(createAndDeliverActivationCodeSuccess)
        .catch((error) => {
            throw error;
        });
};

export default createAndDeliverActivationCode;
