import ReferenceData from "../../constants/ReferenceData";
import { executeGet } from "../../utils/appUtils";

export type DeliverySet = {
    deliveryMask: string;
    deliveryPhoneType: string;
    deliveryType: string;
    originalValue: string;
};

export interface DeliveryOptionsResponseData {
    deliverySet: DeliverySet[];
    destination: string;
    editContactInfoAllowed: boolean;
    flowName: string;
    intlPhoneSupportAllowed: boolean;
}

type DeliveryOptionsSuccessResponse = DeliveryOptionsResponseData | undefined;

const getDeliveryOptions = () => {
    const deliveryOptionsSuccess = (results): DeliveryOptionsSuccessResponse => {
        if (results?.data) {
            return results.data;
        }
    };

    return executeGet(ReferenceData.GET_DELIVERY_OPTIONS, "deliveryOptionsFault")
        .then(deliveryOptionsSuccess)
        .catch((error: unknown) => {
            throw error;
        });
};

export default getDeliveryOptions;
