import isVisible from "core-ui/client/src/app/core/util/isVisible";

const PersonalContactInfoCustomValidator = function PersonalContactInfoCustomValidator() {
    return {
        validatePersonalInfo: validatePersonalInfo
    };

    function validatePersonalInfo(emailAddress, domesticCntryCd, intlCntryCode) {
        let phoneNumber = "";
        let intlNumber = "";
        let cntryCode = "";
        let domesticCntryCode = "";
        let phoneNumberWithNoMasking = "";
        let temp;

        let formStatusWithErrorCd = {
            validForm: true,
            errorCode: ""
        };

        // eslint-disable-next-line no-undef
        if (isVisible(document.getElementById("phoneNumberIdD"))) {
            // eslint-disable-next-line no-undef
            phoneNumber = document.getElementById("phoneNumberIdD").value;
            temp = domesticCntryCd;
            domesticCntryCode = domesticCntryCd.substring(0, 2);
            phoneNumberWithNoMasking = phoneNumber
                .replace("(", "")
                .replace(")", "")
                .replace("-", "")
                .replace(" ", "");
        }
        // eslint-disable-next-line no-undef
        if (isVisible(document.getElementById("phoneNumberIdI"))) {
            // This condition becomes true when the domestic number being changed to INTLNumber
            // eslint-disable-next-line no-undef
            intlNumber = document.getElementById("phoneNumberIdI").value;
            temp = intlCntryCode;
            cntryCode = temp.substring(0, 2);
        }
        // This condition becomes true when the domestic number being changed to INTLNumber
        // eslint-disable-next-line no-undef
        if (
            isVisible(document.getElementById("intlNumberIdD")) &&
            document.getElementById("intlNumberIdD").value &&
            domesticCntryCd
        ) {
            temp = domesticCntryCd;
            // eslint-disable-next-line no-undef
            intlNumber = document.getElementById("intlNumberIdD").value;
            cntryCode = temp.substring(0, 2);
        }

        // eslint-disable-next-line no-undef
        if (isVisible(document.getElementById("phoneNumberIdI"))) {
            // eslint-disable-next-line no-undef
            phoneNumber = document.getElementById("phoneNumberIdI").value;
            temp = intlCntryCode;
            domesticCntryCode = intlCntryCode.substring(0, 2);
            phoneNumberWithNoMasking = phoneNumber
                .replace("(", "")
                .replace(")", "")
                .replace("-", "")
                .replace(" ", "");
        }
        // This condition becomes true when the domestic number being changed to INTLNumber
        // eslint-disable-next-line no-undef
        if (
            isVisible(document.getElementById("intlNumberIdI")) &&
            document.getElementById("intlNumberIdI").value &&
            intlCntryCode
        ) {
            temp = intlCntryCode;
            // eslint-disable-next-line no-undef
            intlNumber = document.getElementById("intlNumberIdI").value;
            cntryCode = temp.substring(0, 2);
        }

        if (intlNumber === "" && (phoneNumber === "" || phoneNumber === "(___) ___-____")) {
            formStatusWithErrorCd = {
                validForm: false,
                errorCode: "both_numbers_empty"
            };
        }

        if (
            domesticCntryCode === "US" &&
            cntryCode === "US" &&
            // eslint-disable-next-line no-undef
            isVisible(document.getElementById("phoneNumberIdD")) &&
            // eslint-disable-next-line no-undef
            isVisible(document.getElementById("phoneNumberIdI")) &&
            // eslint-disable-next-line no-undef
            document.getElementById("phoneNumberIdD").value != "(___) ___-____" &&
            // eslint-disable-next-line no-undef
            document.getElementById("phoneNumberIdI").value != "(___) ___-____"
        ) {
            formStatusWithErrorCd = {
                validForm: false,
                errorCode: "both_numbers_domestic"
            };
        }
        if (
            domesticCntryCode != "US" &&
            cntryCode != "US" &&
            // eslint-disable-next-line no-undef
            isVisible(document.getElementById("intlNumberIdI")) &&
            // eslint-disable-next-line no-undef
            isVisible(document.getElementById("intlNumberIdD")) &&
            // eslint-disable-next-line no-undef
            document.getElementById("intlNumberIdI").value != "" &&
            // eslint-disable-next-line no-undef
            document.getElementById("intlNumberIdD").value != ""
        ) {
            formStatusWithErrorCd = {
                validForm: false,
                errorCode: "both_numbers_INTL"
            };
        }

        if (
            formStatusWithErrorCd.validForm &&
            phoneNumberWithNoMasking !== "" &&
            phoneNumberWithNoMasking !== "__________"
        ) {
            const pattern = /^\d{10}$/;

            formStatusWithErrorCd = {
                validForm: pattern.test(phoneNumberWithNoMasking),
                errorCode: ""
            };
        }

        return formStatusWithErrorCd;
    }
};
PersonalContactInfoCustomValidator.$inject = ["$resource", "$log"];
export default PersonalContactInfoCustomValidator;
