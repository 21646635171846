import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import AccessibilityUtil from "core-ui/client/src/app/core/util/AccessibilityUtil";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import _isUndefined from "lodash/isUndefined";

import registrationEvents from "./events/RegistrationEvents";

const registrationWithGroupPasswordController = function (
    $scope,
    $rootScope,
    $state,
    $timeout,
    eventBus,
    registrationFactory,
    redirectService
) {
    // console.log("Hello from inside registrationWithGroupPasswordController");
    const logger = ExternalLogger.getInstance("registrationWithGroupPasswordController");

    $scope.registrationWithPin = true;
    $scope.registrationWithoutPin = true;
    $scope.registrationWithGroupPassword = true;
    $scope.groupId = "";
    $scope.planEnrollmentCode = "";

    /**
     * focus manager
     * indexed by fieldName
     * @type {{}}
     */
    $scope.focusMgr = {};
    $scope.fieldOnBlur = function (fieldName) {
        if (_isUndefined($scope.registerForm)) {
            return "";
        }
        const field = $scope.registerForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field.$touched && field.$invalid && !fieldFocused) {
            status = "has-error";
        }
        return status;
    };

    $scope.getFormGroupClass = function (fieldName) {
        const method = "getFormGroupClass(" + fieldName + ") ";

        if (_isUndefined($scope.registerForm)) {
            return "";
        }
        const field = $scope.registerForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field && field.$invalid && field.$dirty && !fieldFocused) {
            status = "has-error";
        }

        if (field && field.$valid && field.$dirty) {
            status = "has-success";
        }
        logger.debug("{0} returning [{1}]", [method, status]);
        return status;
    };

    $scope.allowSubmit = function () {
        return $scope.registerForm && $scope.registerForm.$valid;
    };

    $scope.isTouched = function (fieldName) {
        return $scope.registerForm[fieldName].$dirty;
    };

    $scope.isDirty = function (fieldName) {
        if (_isUndefined($scope.registerForm)) {
            return false;
        }
        const dirty =
            $scope.registerForm[fieldName] !== undefined && $scope.registerForm[fieldName].$dirty;

        return dirty;
    };

    $scope.displayFieldMessages = function (fieldName, suppressFocused) {
        const method = "displayFieldMessages(" + fieldName + ") ";
        if (_isUndefined(suppressFocused)) {
            suppressFocused = false;
        }

        if (_isUndefined($scope.registerForm)) {
            return "";
        }
        const field = $scope.registerForm[fieldName];
        if (_isUndefined($scope.registerForm) || !field) {
            return true;
        }
        const conditions =
            (field.$invalid && field.$dirty && !suppressFocused) ||
            (field.$touched && !suppressFocused) ||
            (!field.$pristine && !suppressFocused);

        const logData = {
            conditions: conditions,
            $invalid: field.$invalid,
            $dirty: field.$dirty,
            suppressFocused: suppressFocused,
            $touched: field.$touched,
            $pristine: field.$pristine
        };
        logger.debug("{0} response: {1}", [method, JSON.stringify(logData)]);
        return conditions;
    };

    $scope.getFieldError = function (fieldName) {
        const method = "getFieldError(" + fieldName + ") ";
        let rc = "";
        if (
            _isUndefined($scope.registerForm) ||
            !fieldName ||
            _isUndefined($scope.registerForm[fieldName])
        ) {
            logger.debug("getFieldError(" + fieldName + ") .. unknown field.");
            rc = "";
        } else {
            rc = $scope.registerForm[fieldName].$error;
        }
        logger.debug("{0} rc=[{1}]", [method, JSON.stringify(rc)]);
        return rc;
    };

    $scope.removeInputFocus = function (event) {
        $timeout(function () {
            $scope.focusMgr[event.target.name] = false;
        });
    };

    $scope.setInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = true;
        $scope.registerForm[event.target.name].$setTouched(true);
        $scope.registerForm[event.target.name].$setDirty(true);
    };

    $scope.registerNoPin = function (source) {
        eventBus.dispatch(registrationEvents.REGISTRATION_NO_PIN, this, {
            source: source
        });
    };
    $scope.registerPin = function (source) {
        eventBus.dispatch(registrationEvents.REGISTRATION_PIN, this, {
            source: source
        });
    };
    $scope.registerGroup = function (source) {
        eventBus.dispatch(registrationEvents.REGISTRATION_GROUP, this, {
            source: source
        });
    };
    // focus the error messages
    const handleScreenReader = function (id) {
        AccessibilityUtil.focusElement(id);
    };

    $scope.registerGroupPassword = function () {
        if ($scope.registerForm.$valid) {
            $scope.showSpinner = true;

            // Everything is good, we can continue with registration
            $rootScope.flowName = "registrationFlow";
            const registerData = {
                groupId: $scope.groupId,
                pin: $scope.planEnrollmentCode,
                flowName: $rootScope.flowName
            };

            registrationFactory.validateNPDIData.query(
                registerData,

                function (data) {
                    $scope.showSpinner = false;

                    if (data.errors === false) {
                        // $state.go(data.state, {npdiData: data.npdiGroupAccountInfo});
                        redirectService.redirect(data, "ALL");
                    } else if (data.errors == true && data.validGaID == false) {
                        $scope.autherizationMessage = data.errorCode;
                    }
                },
                function (response) {
                    $scope.showSpinner = false;

                    $scope.autherizationMessage = "";
                    $scope.autherizationMessageParams = "";
                    $scope.ssnError = "";
                    $scope.pinCodeError = "";
                    if (response.headers("exception") !== null) {
                        $scope.autherizationMessage = response.data.error.code;
                        $scope.autherizationMessageParams = response.data.error.errors[0];
                        eventBus.dispatchAmplitude({
                            event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                            event_action: "registration - validateNPDIData.query failed",
                            event_properties: {
                                login_error_code: response?.data?.error?.code,
                                error_message: response?.data?.error?.errors[0]
                            }
                        });
                        handleScreenReader("screenReaderAuth");
                    } else {
                        if (response.message !== null) {
                            $scope.autherizationMessage = "Internal System Error";
                        } else if (response.data !== null) {
                            $scope.ssnError = response.data.ssn;
                            $scope.pinError = response.data.pin;
                        }
                    }
                }
            );
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue
            // Manually set the $dirty flag for each input in the form to trigger validation
            $scope.registerForm.planEnrollmentCode.$dirty = true;
            $scope.registerForm.groupId.$dirty = true;
        }
    };
};

registrationWithGroupPasswordController.$inject = [
    "$scope",
    "$rootScope",
    "$state",
    "$timeout",
    "eventBus",
    "registrationFactory",
    "redirectService"
];
export default registrationWithGroupPasswordController;
