const oauthFactory = function ($resource) {
    const urlParams = new URLSearchParams(window.location.search);
    const login_challenge = urlParams.get("login_challenge");

    //alert(login_challenge);
    //console.log("login" + login_challenge);
    const consent_challenge = urlParams.get("consent_challenge");
    return {
        getLginURL: $resource(
            // "http://localhost:8080/participant-web-services-PROJ_11-SNAPSHOT/rest/nonauth/oauth2/loginCustomization/29fa77f338b54e96adb1857e9471e11c",
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + "rest/nonauth/oauth2/loginCustomization/" + login_challenge,
            {
                flowName: "@flowName"
                // title: "@title",
            },
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),
        getConsentURL: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ +
                "rest/nonauth/oauth2/loginCustomization/getConsentRequest/" +
                consent_challenge,
            //"http://localhost:8080/participant-web-services-PROJ_11-SNAPSHOT/rest/nonauth/oauth2/loginCustomization/a4df27a363274a23b0be149dd6b1400a",
            {
                flowName: "@flowName"
            },
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),
        rejectConsent: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ +
                "rest/nonauth/oauth2/loginCustomization/rejectConsentRequest/" +
                consent_challenge,
            {
                flowName: "@flowName"
            },
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: { "Content-Type": "application/json" }
                }
            }
        ),
        acceptConsent: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + "rest/nonauth/oauth2/loginCustomization/acceptConsentRequest",
            {
                flowName: "@flowName"
            },
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: { "Content-Type": "application/json" }
                }
            }
        )
    };
};
oauthFactory.$inject = ["$resource"];
export default oauthFactory;
