import formatTimingPrefix from "./formatTimingPrefix";
import getLoginAppStartTime from "./getLoginAppStartTime";
import sendAmplitudeEvent from "./sendAmplitudeEvent";

const LOGIN_APP_START = "loginAppStart";

const sentryLoginAppStartMetrics = () => {
    const timingPrefix = formatTimingPrefix();

    const loginStartClickExists = getLoginAppStartTime();

    const eventName = `${timingPrefix}${LOGIN_APP_START}`;

    if (loginStartClickExists) {
        // Remove old and set with new value
        sessionStorage.removeItem(eventName);
    }

    const loginAppStartTime = new Date().getTime();
    const timeZero = sessionStorage.getItem(`${timingPrefix}timeZero`);
    const loginAppStartDelta = String(loginAppStartTime - Number(timeZero));

    const { hash } = window.location;

    const eventPayload = JSON.stringify({
        timestamp: loginAppStartTime,
        hash
    });

    sessionStorage.setItem(eventName, eventPayload);
    sessionStorage.setItem(eventName + "Delta", String(loginAppStartDelta));

    sendAmplitudeEvent({
        event_type: "report_diagnostics",
        event_properties: {
            name: eventName,
            timeToLoad: loginAppStartDelta
        }
    });
};

export default sentryLoginAppStartMetrics;
