import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import MaskedInput from "react-text-mask";

import { checkValidation } from "../../hooks/formValidations";
import TranslationService from "../../services/translationService";
import { personalDetailsForm, routeDestination } from "../../utils/otsConstants";
import OtsAccountModal from "../shared/OtsAccountModal";

const AccountOpenPersonalDetailsForm = ({ handlePersonalDetailsFormSubmit, isRegistered }) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        trigger,
        setValue
    } = useForm();
    const [showSSN, setShowSSN] = useState(false);
    const [translations, setTranslations] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        TranslationService.getTranslations((resp) => {
            setTranslations(resp);
        });
    }, []);

    useEffect(() => {
        if (isRegistered !== null) {
            if (isRegistered) {
                handleOpenModal(true);
            } else {
                handleOpenModal(true);
            }
        }
    }, [isRegistered]);

    const handleFieldChange = (e) => {
        const { name, value, selectionStart } = e.target;
        const cleanedValue = value.replace(/\D/g, "");
        const formattedDate = cleanedValue
            .slice(0, 8)
            .replace(/(\d{2})(\d{0,2})(\d{0,4})/, (_, p1, p2, p3) => {
                let formattedValue = "";
                if (p1) {
                    formattedValue += p1;
                }

                if (p2) {
                    formattedValue += "/" + p2;
                }

                if (p3) {
                    formattedValue += "/" + p3;
                }

                return formattedValue;
            });
        let newPosition =
            selectionStart +
            (formattedDate.length - value.length) +
            (formattedDate.charAt(selectionStart - 1) === "/" ? 1 : 0);

        if (
            (formattedDate.charAt(selectionStart - 1) === "/" && newPosition === 4) ||
            (formattedDate.charAt(selectionStart - 1) === "/" && newPosition === 7)
        ) {
            newPosition = newPosition - 1;
        }
        setValue(name, formattedDate);

        e.target.setSelectionRange(newPosition, newPosition);
    };

    const handleSSNChange = (e) => {
        const { name, value } = e.target;

        const unformmattedValue = value.replace(/-/g, "");
        const formattedValue = unformmattedValue
            .replace(/\D/g, "")
            .replace(/(\d{3})(\d{2})(\d{4})/, `$1-$2-$3`);

        setValue(name, formattedValue);
    };

    const handleFormSubmit = (data) => {
        handlePersonalDetailsFormSubmit(data);
    };

    /* Modal functions */
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);

        if (isRegistered) {
            // reroute to prelogin
            window.location.href = routeDestination.retailRegistered;
        } else {
            // reroute to registration
            window.location.href = routeDestination.retailUnregistered;
        }
    };

    const handleClick = (e) => {
        if (e.type === "click" || (e.type === "keyup" && e.code === "Enter")) {
            e.stopPropagation();
            e.preventDefault();
            setShowSSN(!showSSN);
        }
    };

    const submitButtonStyles = {
        disabled:
            "continue btn btn-primary btn-default__accessible btn-lg btn-block margin-top-default outline-btn is-disabled",
        enabled: "continue btn btn-primary btn-lg btn-block margin-top-default outline-btn"
    };

    return translations ? (
        <div>
            <div className="form-container">
                <div role="form" aria-labelledby="title" aria-describedby="description">
                    <h1 id="title" className="ots-prelogin-header" role="heading">
                        {translations.accountOpen.title}
                    </h1>
                    <div className="inner-container with-padding with-shadow ira-pre-account-form">
                        <p id="description">{translations.accountOpen.description2}</p>

                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <div className="row">
                                {personalDetailsForm.map(({ info, size, label }, idx) => (
                                    <div
                                        key={info}
                                        className={[
                                            "form-group",
                                            errors[info] && "has-error",
                                            size
                                        ].join(" ")}
                                    >
                                        <label htmlFor={info} className="control-label">
                                            {translations.accountOpen.detailsForm[info]}
                                        </label>
                                        {info === "ssn" ? (
                                            <div className="input-group">
                                                <MaskedInput
                                                    autoFocus={idx === 0}
                                                    className="form-control"
                                                    name={info}
                                                    tabIndex="0"
                                                    mask={[
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        "-",
                                                        /\d/,
                                                        /\d/,
                                                        "-",
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/
                                                    ]}
                                                    guide={false}
                                                    type={showSSN ? "text" : "password"}
                                                    id={info}
                                                    {...register(
                                                        info,
                                                        checkValidation(info, label)
                                                    )}
                                                    defaultValue={""}
                                                    onBlur={() => trigger(info)}
                                                    onChange={handleSSNChange}
                                                />
                                                <div className="input-group-addon">
                                                    <span
                                                        className={[
                                                            "fa-regular",
                                                            showSSN ? "fa-eye" : "fa-eye-slash"
                                                        ].join(" ")}
                                                        role="button"
                                                        aria-label={
                                                            showSSN
                                                                ? translations.logon.labels
                                                                      .hidePassLabel
                                                                : translations.logon.labels
                                                                      .showPassLabel
                                                        }
                                                        tabIndex="0"
                                                        onClick={handleClick}
                                                        onKeyUp={handleClick}
                                                    ></span>
                                                </div>
                                            </div>
                                        ) : (
                                            <input
                                                type="text"
                                                className="form-control"
                                                autoFocus={idx === 0}
                                                name={info}
                                                id={info}
                                                tabIndex="0"
                                                {...register(info, checkValidation(info, label))}
                                                defaultValue={""}
                                                onBlur={() => trigger(info)}
                                                onChange={handleFieldChange}
                                            />
                                        )}
                                        {errors[info] && (
                                            <div className="messages">
                                                <p aria-live="assertive" className="msg">
                                                    {errors[info].message}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="row">
                                    <div className="col-xs-12">
                                        <button
                                            type="submit"
                                            aria-disabled={!isValid}
                                            className={
                                                isValid
                                                    ? submitButtonStyles.enabled
                                                    : submitButtonStyles.disabled
                                            }
                                        >
                                            {translations.button.continue}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <OtsAccountModal
                            show={showModal}
                            handleClose={handleCloseModal}
                            translations={translations}
                            isRegistered={isRegistered}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

AccountOpenPersonalDetailsForm.propTypes = {
    handlePersonalDetailsFormSubmit: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    openExistingRetailMessageModal: PropTypes.func,
    isRegistered: PropTypes.bool
};

export default AccountOpenPersonalDetailsForm;
