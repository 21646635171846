import { useState, useEffect } from "react";

interface ILogicSSOLoader {
    loadingTime: number;
    phrases: string[];
}

const useSSOLoaderLogic = ({ loadingTime, phrases }: ILogicSSOLoader) => {
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const [phrasesIndex, setPhrasesIndex] = useState(-1);

    useEffect(() => {
        if (!phrases.length) {
            return;
        }
        setTimeout(() => {
            setLoadingPercentage(100);
        }, 100);
    }, [loadingTime, phrases.length]);

    useEffect(() => {
        const time = 15 / phrases.length;
        for (let i = 0; i <= phrases.length - 1; i++) {
            const delay = i * time || 1;
            setTimeout(() => {
                setPhrasesIndex(i);
            }, 1000 * delay);
        }
    }, [loadingTime, phrases.length]);

    return { loadingPercentage, phrasesIndex };
};
export default useSSOLoaderLogic;
