import ReferenceData from "../../constants/ReferenceData";
import { executePost } from "../../utils/appUtils";
import { CreateAndDeliverActivationCodeSuccessResponse } from "./createAndDeliverActivationCode";

interface CreateAndDeliverActivationCodeByIndIdPayload {
    accu: string | undefined;
    dbName: string;
    deliveryOption: string;
    indid: string;
}

const createAndDeliverActivationCodeByIndId = (
    payload: CreateAndDeliverActivationCodeByIndIdPayload
) => {
    const createAndDeliverActivationCodeByIndIdSuccess = (
        results
    ): CreateAndDeliverActivationCodeSuccessResponse => {
        if (results?.data) {
            return results.data;
        }
    };

    return executePost(
        ReferenceData.CREATE_AND_DELIVER_ACTIVATION_CODE_BY_IND_ID,
        "createAndDeliverActivationCodeByIndIdFault",
        payload
    )
        .then(createAndDeliverActivationCodeByIndIdSuccess)
        .catch((error) => {
            throw error;
        });
};

export default createAndDeliverActivationCodeByIndId;
