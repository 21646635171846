import { IntegratedEligibilityService } from "core-ui/client/src/app/core/integrated/IntegratedEligibilityService";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
// A multiple dimensional associative array for
// states based on flow name and destionation

const stateMap = {};
stateMap.ALL = {};

stateMap.ALL.accountRecoveryConfirm = "accountRecoveryConfirm";
stateMap.ALL.ACCESS_PORTALS = "accountSelection";
stateMap.ALL.accountSelection = "accountSelection";
stateMap.ALL.ACCOUNT_RECOVERY_MFA = "loginHelpDeliveryOptions";
stateMap.ALL.ACTIVATION_CODE_INFO = "activationCodeDeliveryOptions";
stateMap.ALL.ACCOUNT_OPENING = "activationCodeDeliveryOptions";
stateMap.ALL.CHANGE_PASSCODE = "toForceChangePassword";
stateMap.ALL.CHANGE_COMM_PREF = "changeCommPref";
stateMap.ALL.ACCOUNT_SETUP = "accountSetup";
stateMap.ALL.CONTACT_INFO = "accountSetup";
stateMap.ALL.emptyDeliveryOptions = "activationCodeDeliveryOptions";
stateMap.ALL.ACCOUNT_RECOVERY = "accountRecovery";
stateMap.ALL.ACCOUNT_RECOVERY_MULTIPLE_USERS = "accountRecoveryMultipleUsrs";
stateMap.ALL.profile = "profile";
stateMap.ALL.npdi = "npdi";
stateMap.ALL.NPDI_ACCOUNT_SETUP = "npdiAccountSetup";
stateMap.ALL.npdiLinkAccounts = "npdiLinkAccounts";
stateMap.ALL.registrationSummary = "registrationSummary";
stateMap.ALL.REGISTER_USER = "registerInfo";
stateMap.ALL.UPDATE_CONTACT = "contactUpdate";
stateMap.ALL.NO_CONTACT_INFO = "noContactInfo";
stateMap.ALL.preOauthLogin = "preOauthLogin";
stateMap.ALL.oauthLogin = "oauthLogin";
stateMap.ALL.STATE_ACCOUNT_EXISTS = "accountExists";
stateMap.ALL.LOGIN_HARDSTOP = "loginHardStopError";
stateMap.ALL.ID_PROOFING_QUERIES = "idProofQueries";
stateMap.ALL.MAIL_PIN = "mailPin";
stateMap.ALL.ID_PROOFING_PIN_STATE = "idProofWithPin";
stateMap.ALL.ID_PROOFING_OTP_STATE = "otpIdentityVerification";
stateMap.ALL.ID_PROOFING_CONSENT = "idProofConsent";
stateMap.ALL.IND_ID_SELECTION = "indIdSelection";
stateMap.ALL.ID_PROOFING_OTP_VERIFY_CODE = "otpIdentityVerificationCodeEntry";

stateMap.ALL.ONEID_CONFIRMATION_STATE = "linkedConfimration";
stateMap.ALL.ONEID_PRIMARY_PLAN_STATE = "choosePrimaryPlan";
stateMap.ALL.ONEID_LINKING_PAGE_STATE = "linkablePlans";

stateMap.MFA = {};
stateMap.MFA.CONTACT_INFO = "contactUs";

stateMap.LOGINHELP = {};
stateMap.LOGINHELP.ACCOUNT_RECOVERY = "accountRecovery";

stateMap.LOGINURL = {};
stateMap.ERRORHEADER = {};

const redirectService = function (
    $location,
    $rootScope,
    $state,
    $window,
    AuthenticationStatusService,
    LogoutService,
    LanguageCodeFactory,
    legSSOTokenRetrievalFactory,
    npdiRegistrationService
) {
    /** * Logger. */
    const logger = ExternalLogger.getInstance("redirectService");

    return {
        redirect: function (data, flowName, isVerifyState = false) {
            logger.debug(
                "redirect service called ",
                flowName,
                String(isVerifyState),
                data.destinationUrl
            );

            let isRetail;
            let homePageEligibility;
            let liatCode = data.liatCode;
            const otpParams =
                String(isVerifyState) === "OTS" || String(isVerifyState) === "DC"
                    ? isVerifyState
                    : null;
            const accountsOverviewUrl = "participant/accounts/#/overview";
            const dashboardUrl = "participant/home/#/dashboard/retirement-income";
            const isDeepLink = data.destinationUrl === "d";
            const fromContactUpdate = String(isVerifyState) === "Contact";

            function determineRedirect() {
                let accessPortalGroupedByAccu;
                if (data === undefined) {
                    logger.debug("redirect. flowName=[{0}] data={1}", [flowName, "undefined"]);
                } else {
                    logger.debug("redirect. flowName=[{0}] data={1}", [
                        flowName,
                        JSON.stringify(data)
                    ]);
                    accessPortalGroupedByAccu = data.accessPortalGroups;
                }

                if (data.state == "OAUTH_FLOW") {
                    $window.location.href = data.destinationUrl;
                }

                //if WNPECN is true ignore the state and route landing page
                if (
                    npdiRegistrationService.getPecByPass() &&
                    data.state === "REGISTER_USER" &&
                    data.destinationUrl === "liatLanding"
                ) {
                    data.state = null;
                }
                // Coming from Contact update, use deep link if that was where is started
                if (
                    fromContactUpdate &&
                    $rootScope.destinationUrl &&
                    $rootScope.destinationUrl.indexOf("#/d") > 0
                ) {
                    data.destinationUrl = $rootScope.destinationUrl;
                }

                if (
                    data.state !== null &&
                    data.state !== undefined &&
                    data.state !== "ACCESS_PORTALS" &&
                    data.state !== "FULLY_AUTHENTICATED" &&
                    data.state !== "AUTHENTICATED" &&
                    data.state !== "ID_PROOFING_OTP_EXPIRED"
                ) {
                    logger.debug("Evaluating destination state flowname=[{0}], state=[{1}] : {2}", [
                        flowName,
                        data.state,
                        JSON.stringify(stateMap)
                    ]);
                    $state.go(stateMap[flowName][data.state], { flowName: otpParams });
                } else if (data.state !== null && data.state === "ID_PROOFING_OTP_EXPIRED") {
                    $state.go("mailPin", { optOut: "otp-Expired" });
                } else if (data.destinationUrl !== null) {
                    logger.info("Participant current languageCode: ", data.languageCode);
                    let redirectSpanishSite = false;
                    if (data.languageCode && data.languageCode === "SPN") {
                        redirectSpanishSite = true;
                    }
                    logger.debug("Evaluating destination url...[{0}]", [data.destinationUrl]);
                    // participant/re-enroll/#/3626399/455196-01/landing
                    if (data.destinationUrl.indexOf("re-enroll") >= 0) {
                        logger.debug("desitnation is re-enrollment [{0}]", [data.destinationUrl]);
                        console.log("deeplink re-enrollment destination url", data.destinationUrl);
                        /* var items = data.destinationUrl.split('/');
                        var params = {indId: items[3], gaId: items[4], postUrlData:data};
                        $state.go('re-enroll', params );
                        */
                        if (redirectSpanishSite) {
                            redirectToSpanishSite(data.destinationUrl);
                            console.log(
                                "deeplink re-enrollment SpanishSite destination url",
                                data.destinationUrl
                            );
                        } else {
                            $window.location.href = "../../" + data.destinationUrl;
                            console.log(
                                "deeplink re-enrollment regular destination url",
                                data.destinationUrl
                            );
                        }
                    } else if (data.destinationUrl.indexOf("loginHelp") >= 0) {
                        logger.debug("destination is loginHelp [{0}]", [data.destinationUrl]);
                        if (redirectSpanishSite) {
                            redirectToSpanishSite(data.destinationUrl);
                        } else {
                            $window.location.href = "../../" + data.destinationUrl;
                        }
                    } else if (data.destinationUrl.indexOf("enroll") >= 0) {
                        logger.debug("desitation is enrollment [{0}]", [data.destinationUrl]);
                        if (redirectSpanishSite) {
                            redirectToSpanishSite(data.destinationUrl);
                        } else {
                            $window.location.href = "../../" + data.destinationUrl;
                        }
                    } else if (data.destinationUrl.indexOf("marketing-consent") >= 0) {
                        logger.debug("destination is open account [{0}]", [data.destinationUrl]);
                        if (redirectSpanishSite) {
                            redirectToSpanishSite(data.destinationUrl);
                        } else {
                            if (otpParams === "DC") {
                                $state.go("linkablePlans");
                            } else {
                                $window.location.href = "../../" + data.destinationUrl;
                            }
                        }
                    } else if (
                        data.destinationUrl.indexOf("liatLanding") >= 0 ||
                        (isRetail && !isDeepLink)
                    ) {
                        logger.debug("desitation is liatLanding [{0}]", [
                            data.destinationUrl,
                            data.liatCode
                        ]);
                        // let { gaId, indId } = data.primaryPlan ? data.primaryPlan : data.accessPortalGroups[0].accessPortalList[0];

                        let liatUrl = "participant/home/#/dashboard/retirement-income";
                        let homePageUrl;

                        if (homePageEligibility === false) {
                            liatUrl = accountsOverviewUrl;
                        }

                        homePageUrl = liatUrl;

                        logger.debug("liat url {0}", [homePageUrl]);
                        if (redirectSpanishSite) {
                            redirectToSpanishSite(homePageUrl);
                        } else {
                            $window.location.href = "../../" + homePageUrl;
                        }

                        homePageUrl = liatUrl;

                        logger.debug("liat url {0}", [homePageUrl]);
                        if (redirectSpanishSite) {
                            redirectToSpanishSite(homePageUrl);
                        } else {
                            $window.location.href = "../../" + homePageUrl;
                        }

                        logger.debug("liat url {0}", [homePageUrl]);
                        if (redirectSpanishSite) {
                            redirectToSpanishSite(homePageUrl);
                        } else {
                            $window.location.href = "../../" + homePageUrl;
                        }
                    } else if (data.destinationUrl.indexOf("choose-primary") >= 0) {
                        logger.debug("choose-primary plan state redirect");
                        $state.go("choosePrimaryPlan");
                    } else if (data.destinationUrl.indexOf("iraTerms") >= 0) {
                        logger.debug("destination is iraTerms [{0}]", [data.destinationUrl]);
                        $window.location.href = "../../" + data.destinationUrl;
                    } else if (
                        data.destinationUrl === "d" ||
                        data.destinationUrl.indexOf("#/d") >= 0
                    ) {
                        console.log("destination is deepLink [{0}]", [data.destinationUrl]);
                        let destinationUrl = "";
                        if (redirectSpanishSite) {
                            destinationUrl =
                                "participant/" +
                                window.location.search +
                                "#/d/" +
                                $rootScope.featureName;
                            if (
                                $rootScope.gaId !== undefined &&
                                $rootScope.gaId !== null &&
                                $rootScope.gaId !== ""
                            ) {
                                destinationUrl += "/" + $rootScope.gaId;
                            }
                            logger.debug("desitation is deepLink url [{0}]", [destinationUrl]);
                            redirectToSpanishSite(destinationUrl);
                        } else {
                            if (data["deepLinkedUrl"]) {
                                logger.debug("desitation is deepLink url [{0}] without gaId", [
                                    destinationUrl
                                ]);
                                $state.go("indIdSelection", {
                                    deeplink: {
                                        deepLinkedUrl: data.deepLinkedUrl,
                                        plansMapWithIndid: data.plansMapWithIndid
                                    }
                                });
                            } else {
                                if ($rootScope.featureName !== undefined) {
                                    destinationUrl =
                                        "participant/" +
                                        window.location.search +
                                        "#/d/" +
                                        $rootScope.featureName;
                                } else {
                                    destinationUrl = data.destinationUrl;
                                }
                                if (
                                    $rootScope.gaId !== undefined &&
                                    $rootScope.gaId !== null &&
                                    $rootScope.gaId !== ""
                                ) {
                                    destinationUrl += "/" + $rootScope.gaId;
                                }
                                logger.debug("desitation is deepLink url [{0}]", [destinationUrl]);
                                // eslint-disable-next-line no-restricted-syntax
                                console.debug("desitation is deepLink url [{0}]", [destinationUrl]);
                                $window.location.href = "../../" + destinationUrl;
                            }
                        }
                    } else if (data.destinationUrl.indexOf("participant") >= 0) {
                        let redirectUrl = data.destinationUrl;

                        if (homePageEligibility) {
                            redirectUrl = dashboardUrl;
                        } else {
                            redirectUrl = accountsOverviewUrl;
                        }
                        if (liatCode === "LIAT_CODE1") {
                            redirectUrl = accountsOverviewUrl;
                        }
                        if (data.isDeepLink) {
                            redirectUrl = data.destinationUrl;
                        }
                        if (redirectSpanishSite) {
                            redirectToSpanishSite(redirectUrl);
                        } else {
                            $window.location.href = "../../" + redirectUrl;
                        }
                    } else if (data.destinationUrl.indexOf("registrationSummary") >= 0) {
                        logger.debug("desitation is registrationSummary [{0}]", [
                            data.destinationUrl
                        ]);
                        //   $location.path('registration-summary');
                        $state.go("registrationSummary");
                    } else if (data.destinationUrl.indexOf("NPDI_LEGACY_REDIRECT") >= 0) {
                        logger.debug("desitation is NPDI_LEGACY_REDIRECT [{0}]", [
                            data.destinationUrl
                        ]);
                        // eslint-disable-next-line no-undef
                        const redirectURL = __iframeUrl__ + "/legacyNpdiBegin.do?accu=GwrsWR";
                        $window.location.href = redirectURL;
                    } else if (data.destinationUrl.indexOf("indIdSelection") >= 0) {
                        logger.debug("desitation is indIdSelection [{0}]", [data.destinationUrl]);
                        $state.go("indIdSelection");
                    } else if (data.destinationUrl.indexOf("oauth") >= 0) {
                        logger.debug("desitation is oauth [{0}]", [data.destinationUrl]);
                        $state.go("oauth");
                    } else if (accessPortalGroupedByAccu.length > 1) {
                        logger.debug("desitation is ACCU-selection [{0}]", [data.destinationUrl]);
                        $state.go(stateMap.ALL.accountSelection);
                    } else if (
                        accessPortalGroupedByAccu.length == 1 ||
                        data.destinationUrl == "LEGACY_SSO_INFO" ||
                        data.destinationUrl == "ACCESS_PORTALS"
                    ) {
                        logger.debug("desitation is redirectToLegacy [{0}]", [data.destinationUrl]);
                        redirectToLegacy(accessPortalGroupedByAccu[0].accessPortalList[0]);
                    }
                }
            }

            if (isVerifyState) {
                $rootScope.showLogin = false;

                if (isVerifyState === "loginMasterController") {
                    determineRedirect();
                } else {
                    setTimeout(() => {
                        IntegratedEligibilityService().then(
                            (response) => {
                                isRetail = response.isRetail;
                                homePageEligibility = response.homePageEligibility;

                                if (response.samlTokenError && response.samlTokenError.error) {
                                    if (response.samlTokenError.code === "LIAT_CODE1") {
                                        liatCode = response.samlTokenError.code;
                                    }
                                }
                                determineRedirect();
                            },
                            (error) => {
                                homePageEligibility = error.data.homePageEligibility || false;
                                determineRedirect();
                            }
                        );
                    }, 1500);
                }
            } else {
                determineRedirect();
            }
        },

        redirectLegSSOPortal: function (portal) {
            redirectToLegacy(portal);
        },

        setLoginURL: function (url, data, flow) {
            stateMap.LOGINURL.URL = url;
            stateMap.LOGINURL.HEADER = data;
            stateMap.LOGINURL.FLOW = flow;
        },

        getLoginURL: function () {
            return stateMap.LOGINURL;
        }
    };

    function redirectToSpanishSite(destinationUrl) {
        AuthenticationStatusService.clearSpanishLanguageCookies().then(
            function (resp) {
                console.log("Login Spanish cookies have been cleared.", resp);
                LanguageCodeFactory.setLanguageCookies.query(
                    {},
                    function (data) {
                        console.log("Cloned cookies for Spanish site", data);
                        $window.location.href = getSpanishURL(destinationUrl);
                    },
                    function (error) {
                        logger.error("Failed to clone the cookie for Spanish site", error);
                        $window.location.href = "../../" + destinationUrl;
                    }
                );
            },
            function (error) {
                logger.error("Login failed to clearSpanishLanguageCookies", error);
                $window.location.href = "../../" + destinationUrl;
            }
        );
    }

    function getSpanishURL(destinationUrl) {
        const currentUrl = $window.location.href;
        const https = "https://";
        const http = "http://";
        const period = ".";
        const toLangCode = "es";
        let newUrl = currentUrl;
        let idx = currentUrl.indexOf("participant/");
        if (idx < 0) {
            idx = currentUrl.indexOf($window.location.pathname);
        }
        if (idx >= 0) {
            const newOrigin = currentUrl.substr(0, idx);
            console.log("newOrigin:", newOrigin);
            // prepend domain part for the url with language code
            // E.g. https://proj9.retirementpartner.com/participant/home/#/dashboard/retirement-income
            // Will be changed to
            // https://es.proj9.retirementpartner.com/participant/home/#/dashboard/retirement-income
            if (
                newOrigin.indexOf(https + toLangCode) < 0 &&
                newOrigin.indexOf(http + toLangCode) < 0
            ) {
                newUrl = newOrigin.replace(https, https + toLangCode + period);
                newUrl = newUrl.replace(http, http + toLangCode + period);
            }
            newUrl = newUrl + destinationUrl;
        } else {
            console.log("Failed to get current site origin", currentUrl);
            newUrl = "../../" + destinationUrl;
        }
        console.log("newUrl: ", newUrl);
        return newUrl;
    }

    function redirectToLegacy(portal) {
        const redirectLegSSOPortalParams = {
            indId: portal.indId,
            gaId: portal.gaId
        };

        legSSOTokenRetrievalFactory.query(redirectLegSSOPortalParams, function (data) {
            LogoutService.logOutServices.query(function (logoutData) {
                console.log("Session is invalid " + logoutData);

                // eslint-disable-next-line no-undef
                const formActionURL =
                    // eslint-disable-next-line no-undef
                    __iframeUrl__ + portal.portal + "&synctoken=" + data + "&gaId=" + portal.gaId;
                pushAnalyticsEvent(data.userRegId, formActionURL);
                document.getElementById("portalRedirectForm").setAttribute("action", formActionURL);
                document.getElementById("portalRedirectForm").submit();
            });
        });
    }

    function pushAnalyticsEvent(userId, url) {
        if (userId !== null) {
            $window.dataLayer.push({
                uniqueId: userId,
                event: "legacyRedirectFromRegistration",
                redirectUrl: url
            });
        }
    }
};

redirectService.$inject = [
    "$location",
    "$rootScope",
    "$state",
    "$window",
    "AuthenticationStatusService",
    "LogoutService",
    "LanguageCodeFactory",
    "legSSOTokenRetrievalFactory",
    "npdiRegistrationService"
];
export default redirectService;
