// import foo from "./bar";

const BeneficiaryRouteStates = function ($stateProvider) {
    $stateProvider

        .state("registrationSummary.beneficiary", {
            abstract: true,
            template: "<div ui-view></div>",
            controller: "beneficiaryController as vm",
            params: {
                inEnrollment: true
            },
            resolve: {
                groupId: [
                    "primaryPlanService",
                    function (primaryPlanService) {
                        return primaryPlanService.getPrimaryPlan().then(function (primaryPlan) {
                            return primaryPlan.gaId;
                        });
                    }
                ],
                individualId: [
                    "primaryPlanService",
                    function (primaryPlanService) {
                        return primaryPlanService.getPrimaryPlan().then(function (primaryPlan) {
                            return primaryPlan.indId;
                        });
                    }
                ]
            }
        })

        // List view
        .state("registrationSummary.beneficiary.list", {
            url: "",
            templateUrl: "module/beneficiary/view/list/list.html",
            controller: "beneficiaryListController as list",
            params: {
                inEnrollment: true
            }
        });
};

BeneficiaryRouteStates.$inject = ["$stateProvider"];
export default BeneficiaryRouteStates;
