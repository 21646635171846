import formatTimingPrefix from "./formatTimingPrefix";
import getLoginAppStartTime from "./getLoginAppStartTime";
import sendAmplitudeEvent from "./sendAmplitudeEvent";

const VERIFY_CODE_SIGN_IN = "verifyCodeSignIn";

const sentryVerifyCodeSignIn = () => {
    const timingPrefix = formatTimingPrefix();

    const eventName = `${timingPrefix}${VERIFY_CODE_SIGN_IN}`;

    const verifyCodeSignInExists = sessionStorage.getItem(eventName);

    if (verifyCodeSignInExists) {
        // Remove old and set with new value
        sessionStorage.removeItem(eventName);
    }

    //user clicks verfiy code sign in button
    const verifyCodeSignInStartTime = new Date().getTime();
    const loginAppStartTime = getLoginAppStartTime();

    if (loginAppStartTime) {
        // Measure the time from app loading to clicking login button
        const verifyCodeSignInDelta = String(
            verifyCodeSignInStartTime - Number(loginAppStartTime.timestamp)
        );

        const { hash } = window.location;

        const eventPayload = JSON.stringify({
            timestamp: verifyCodeSignInStartTime,
            hash
        });

        sessionStorage.setItem(eventName, eventPayload);
        sessionStorage.setItem(eventName + "Delta", verifyCodeSignInDelta);

        const loginButtonToEnterCodeDelta =
            verifyCodeSignInStartTime -
            Number(sessionStorage.getItem(timingPrefix + "loginButtonClick"));

        sendAmplitudeEvent({
            event_type: "report_diagnostics",
            event_properties: {
                name: eventName + "Delta",
                timeToLoad: verifyCodeSignInDelta
            }
        });

        sendAmplitudeEvent({
            event_type: "report_diagnostics",
            event_properties: {
                name: "loginButtonClickToVerifyCodeClickDelta",
                timeToLoad: loginButtonToEnterCodeDelta
            }
        });
    }
};

export default sentryVerifyCodeSignIn;
