/**
 *
 * @name app.registration.events.RegistrationEvents
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  These are the events for registration views
 */

const root = "RegistrationEvent.";

const RegistrationEvents = {
    //multi-use
    REGISTRATION_NO_PIN: root + "registration_without_pin_link_clicked_event",
    REGISTRATION_PIN: root + "registration_with_pin_link_clicked_event",
    REGISTRATION_GROUP: root + "regstration_with_group_password_link_clicked_event",
    LEGACY_REDIRECT: root + "redirect_to_legacy_link_clicked_event",
    //registrationSummary
    PRINT: root + "beneficiary_confirmation_print_button_clicked_event",
    OTHER_CONTRIB_TOGGLE: root + "other_contribution_toggle_on_off_clicked_event",
    AGREE: root + "agree_continue_button_clicked_event",
    //registrationWithoutPin
    ID_TYPE_CHANGE: root + "login_help_identifier_type_change_event",
    SSN_CHANGE: root + "login_help_ssn_input_field_change_event",
    ZIP_CHANGE: root + "login_help_zip_code_input_field_change_event",
    UNIQUE_ID_CHANGE: root + "login_help_unique_id_field_change_event",
    UNIQUE_ID_TOOLTIP_SELECT: root + "login_help_unique_id_tooltip_select_event",
    PLAN_ID_CHANGE: root + "login_help_plan_id_field_change_event",
    LAST_NAME_CHANGE: root + "login_help_last_name_input_field_change_event",
    DOB_CHANGE: root + "login_help_date_of_birth_input_field_change_event",
    STREET_CHANGE: root + "login_help_street_address_input_field_change_event",
    SUBMIT: root + "submit_button_clicked_event",
    //registrationWithPin
    SSN_CHANGE_PIN: root + "ssn_input_field_changed_event",
    PIN_CHANGE: root + "pin_input_field_changed_event",
    SUBMIT_PIN: root + "submit_button_with_pin_clicked_event",
    SUBMIT_IDPROOF_PIN: root + "submit_button_with_idproof_pin_clicked_event",
    SUBMIT_NO_PIN: root + "submit_button_without_pin_clicked_event",
    LOGIN_ERROR_CODE: root + "login_error_code",
    //registrationWithPlanEnrollmentCode
    SUBMIT_PLAN_ENROLLMENT_CODE:
        "registration_I_have_a_plan_enrollment_code_continue_button_clicked"
};

export default RegistrationEvents;
