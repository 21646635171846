const StateDestinationFactory = function ($q, $http) {
    const factory = {};

    factory.determineNextState = function (flowName) {
        const deferred = $q.defer();

        $http
            .get(
                // eslint-disable-next-line no-undef
                __authenticationUrl__ + "rest/partialauth/state/getNextUserState/" + flowName
            )
            .success(function (data) {
                deferred.resolve(data);
            })
            .error(function (data) {
                // TODO: Use logger to log the error message

                deferred.reject(data);
            });

        return deferred.promise;
    };

    factory.getParticipantDataAsync = function () {
        const deferred = $q.defer();
        $http
            .get(
                // eslint-disable-next-line no-undef
                __authenticationUrl__ +
                    "rest/partialauth/retirementIncomeView/participantLoginInformation"
            )
            .success(function (data) {
                deferred.resolve(data);
            })
            .error(function (data) {
                // TODO: Use logger to log the error message

                deferred.reject(data);
            });
        return deferred.promise;
    };

    factory.logBrowserInfo = function () {
        const deferred = $q.defer();
        $http
            .get(
                // eslint-disable-next-line no-undef
                __authenticationUrl__ + "rest/nonauth/logBrowserInfo"
            )
            .success(function (data) {
                deferred.resolve(data);
            })
            .error(function (data) {
                // TODO: Use logger to log the error message

                deferred.reject(data);
            });
        return deferred.promise;
    };

    factory.routeDetermination = function () {
        const deferred = $q.defer();
        $http
            .get(
                // eslint-disable-next-line no-undef
                __authenticationUrl__ + "rest/partialauth/routeDetermination"
            )
            .success(function (data) {
                deferred.resolve(data);
            })
            .error(function (data) {
                // TODO: Use logger to log the error message
                deferred.reject(data);
            });
        return deferred.promise;
    };

    factory.determineNextDest = function (flowName) {
        const deferred = $q.defer();

        $http
            .get(
                // eslint-disable-next-line no-undef
                __baseApiUrl__ +
                    "/participant-web-services/rest/partialauth/dest/getNextAuthDestination/" +
                    flowName
            )
            .success(function (data) {
                deferred.resolve(data);
            })
            .error(function (data) {
                // TODO: Use logger to log the error message

                deferred.reject(data);
            });

        return deferred.promise;
    };

    factory.getInBoundUser = function () {
        const deferred = $q.defer();

        $http
            // eslint-disable-next-line no-undef
            .get(__authenticationUrl__ + "rest/nonauth/getInBoundUser")
            .success(function (data) {
                deferred.resolve(data);
            })
            .error(function (data) {
                // TODO: Use logger to log the error message

                deferred.reject(data);
            });

        return deferred.promise;
    };

    return factory;
};

StateDestinationFactory.$inject = ["$q", "$http"];
export default StateDestinationFactory;
