const AuthenticationService = function ($resource) {
    return {
        authenticate: $resource(
            __authenticationUrl__ + "rest/nonauth/authenticate/",
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json",
                        requestSrc: "empower_browser"
                    }
                }
            }
        ),
        authenticateLite: $resource(
            __authenticationUrl__ + "rest/nonauth/authenticateLite/",
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json",
                        requestSrc: "empower_browser"
                    }
                }
            }
        ),
        isAuthenticated: $resource(
            __baseApiUrl__ + "/participant-web-services/rest/nonauth/isAuthenticated",
            {},
            {
                query: {
                    method: "POST",
                    isArray: false
                }
            }
        )
    };
};

AuthenticationService.$inject = ["$resource", "$window"];
export default AuthenticationService;
