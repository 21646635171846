/* eslint-disable react/prop-types */
import React from "react";

import LogService from "core-ui/client/src/app/core/logger/LogService";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

import { sendOneTimePasswordCode } from "../../../services/idProof/otp/OneTimePasswordService";
import StateChangeService from "../../../services/stateChangeService";
import TranslationService from "../../../services/translationService";

class IdentityVerificationComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            translations: null,
            phoneNumber: null,
            phoneNumberError: null,
            serviceError: null,
            isLoading: false,
            isOtsUser: props.isOtsUser
        };
    }

    /**
     * convenience function for 'empty' string.
     */
    isEmpty = (str) => {
        if (str === null || str === undefined) {
            return true;
        }
        if (str.replace(/\s/g, "") === "") {
            return true;
        }
        return false;
    };

    /**
     * returns true if the supplied string is not a valid phone number.
     */
    isInvalidPhoneNumberPattern = (str) => {
        if (str === null || str === undefined) {
            return true;
        }
        const phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        const rc = !phoneNumberPattern.test(str);
        return rc;
    };

    /**
     * validate the phone number value.
     */
    validatePhoneNumber = (value) => {
        this.setState({ phoneNumber: value });
        if (!value || this.isEmpty(value)) {
            this.setState({
                phoneNumberError:
                    this.state.translations?.oneTimePassword?.identityVerification?.errors
                        ?.phoneNumberRequired
            });
            return;
        }
        if (this.isInvalidPhoneNumberPattern(value)) {
            this.setState({
                phoneNumberError:
                    this.state.translations?.oneTimePassword?.identityVerification?.errors
                        ?.phoneNumberInvalid
            });
            return;
        }
        this.setState({ phoneNumberError: null });
    };

    /**
     * handle a change to the phoneNumber input.
     */
    handlePhoneNumberChange = (event) => {
        const value = event.target.value;
        let currentValue = this.state.phoneNumber;
        if (!value) {
            currentValue = "";
        }
        LogService.getLogger().debug(
            "handlePhoneNumberChange [" + currentValue + "] [" + value + "]"
        );
        if (!this.isInvalidPhoneNumberPattern(value) || !!currentValue) {
            this.validatePhoneNumber(value);
        }
    };

    /**
     * handle a blur to the phoneNumber input.
     */
    handlePhoneNumberBlur = (event) => {
        const value = event.target.value;
        LogService.getLogger().debug(
            "handlePhoneNumberBlur [" + this.state.phoneNumber + "] [" + value + "]"
        );
        this.validatePhoneNumber(value);
    };

    /**
     *  render the phone number error
     */
    renderPhoneNumberErrorMessage = () => {
        if (this.state.phoneNumberError) {
            return (
                <div className="form-group has-error" id="screenReader" tabIndex="0">
                    <span id="helpBlock" className="help-block">
                        {this.state.phoneNumberError}
                    </span>
                </div>
            );
        }
    };

    /**
     *  render the service error block
     */
    renderServiceErrorMessage = () => {
        if (this.state.serviceError) {
            return (
                <div className="alert alert-warning margin-top-100">
                    <p>{this.state.serviceError}</p>
                </div>
            );
        }
    };

    /**
     * render the submit button.
     * moved to a separate function to account for all the loading/disabled/enabled logic.
     */
    renderSubmitButton = () => {
        //btn-lg
        let className = "btn btn-primary";
        const isLoading = this.state.isLoading;
        const isFormValid = this.isFormValid();
        let disabled = false;
        let label = this.state.translations?.oneTimePassword?.identityVerification?.agreeButton;

        if (isFormValid) {
            if (isLoading) {
                disabled = true;
                label =
                    this.state.translations?.oneTimePassword?.identityVerification
                        ?.agreeButtonProcessing;
                className += " pw-loader disabled-loading";
            }
        } else {
            disabled = true;
        }

        return (
            <button
                className={className}
                id="idverify-submit"
                disabled={disabled}
                onClick={this.handleSubmit}
            >
                {label}
            </button>
        );
    };

    /**
     * load the translations.
     */
    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    /**
     *  handle error messaging.
     *  if 'data' is provided, it could have a custom error code.
     *  if 'data' is not provided, then an http exception occurred.
     */
    handleError = (data, error) => {
        //console.log('handleError',data, error);
        LogService.getLogger().error(
            "handleError. data=[" +
                (data ? JSON.stringify(data) : "null") +
                "] error: " +
                (error ? JSON.stringify(error) : "null")
        );
        this.setState({ isLoading: false });

        let errorCode = "";
        if (data && data.errorCode) {
            errorCode = data.errorCode;
        } else {
            //shows error in alert box.
            errorCode = error.response.data.error.code;
        }

        StateChangeService.goToState("idProofError", { errorCode: errorCode });
    };

    /**
     *  Handle a successful response.
     */
    handleSuccess = (data) => {
        //console.log('handleSuccess',data);
        LogService.getLogger().error(
            "handleSuccess. data=[" + (data ? JSON.stringify(data) : "null") + "]"
        );
        this.setState({ isLoading: false });
        if (this.props.options.onConfirm) {
            this.props.options.onConfirm(this.state.phoneNumber, data);
        }
    };

    /**
     * Handle the submit button event.
     */
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ serviceError: null });
        this.setState({ isLoading: true });
        this.validatePhoneNumber(this.state.phoneNumber);

        if (this.isFormValid()) {
            sendOneTimePasswordCode(
                { value: this.state.phoneNumber.replace(/\D+/g, "") },
                this.handleSuccess,
                this.handleError
            );
        } else {
            this.setState({ isLoading: false });
        }
    };

    /**
     * onclick for the cancel button.
     */
    onCancelClick = (e) => {
        e.preventDefault();
        if (this.props.options.onCancel) {
            this.props.options.onCancel();
        }
    };

    /**
     * convenience function for if the form is valid.
     */
    isFormValid = () => {
        return !!this.state.phoneNumber && !this.state.phoneNumberError;
    };

    /**
     * get the class/style to be used for the specified element.
     */
    getFieldClass = (val) => {
        let rc = "form-group";
        if (this.state[val + "Error"]) {
            rc += " has-error";
        }
        return rc;
    };

    render() {
        if (!this.state.translations) {
            return null;
        }
        return (
            <div
                data-testid="identity-verification-component"
                className="col-md-10 col-md-offset-1 registration"
            >
                <header className="contained-form-header">
                    <h1>
                        {this.state.translations?.oneTimePassword?.identityVerification?.header}
                    </h1>
                    <p>
                        {this.state.translations?.oneTimePassword?.identityVerification?.subHeader}
                    </p>
                </header>

                <div className="inner-container with-padding with-shadow">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.state.translations?.oneTimePassword?.identityVerification
                                ?.message?.paragraph1
                        }}
                    ></div>
                    {!this.state.isOtsUser ? (
                        <div
                            className="margin-top-100"
                            dangerouslySetInnerHTML={{
                                __html: this.state.translations?.oneTimePassword
                                    ?.identityVerification?.message?.paragraph2
                            }}
                        ></div>
                    ) : (
                        <div
                            className="margin-top-100"
                            dangerouslySetInnerHTML={{
                                __html: this.state.translations?.oneTimePassword
                                    ?.identityVerification?.message?.paragraph3
                            }}
                        ></div>
                    )}
                    <div className="margin-top-200">
                        <div className={this.getFieldClass("phoneNumber")}>
                            <label className="control-label" htmlFor="phoneNumber">
                                {
                                    this.state.translations?.oneTimePassword?.identityVerification
                                        ?.phoneNumber
                                }
                            </label>
                            {/* phoneNumber input */}
                            <MaskedInput
                                mask={[
                                    "(",
                                    /[1-9]/,
                                    /\d/,
                                    /\d/,
                                    ")",
                                    " ",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/
                                ]}
                                className="form-control col-12"
                                placeholder="(###) ###-####"
                                guide={false}
                                id="phoneNumber"
                                onBlur={this.handlePhoneNumberBlur}
                                onChange={this.handlePhoneNumberChange}
                                autoComplete="off"
                            />
                        </div>
                        {this.renderPhoneNumberErrorMessage()}
                        {this.renderServiceErrorMessage()}
                    </div>
                    <div className="row margin-top-200">
                        <div className="col-md-6 col-sm-12">
                            {/* cancel button */}
                            {/* btn-lg */}
                            {!this.state.isOtsUser && (
                                <button
                                    className="btn btn-primary"
                                    onClick={this.onCancelClick}
                                    id="idverify-cancel"
                                >
                                    {
                                        this.state.translations?.oneTimePassword
                                            ?.identityVerification?.skipButton
                                    }
                                </button>
                            )}
                        </div>
                        <div className="col-md-6 col-sm-12 text-right">
                            {/* submit button */}
                            {this.renderSubmitButton()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /*#################################################################
     # LifeCycle Hooks
     #################################################################*/

    componentDidMount() {
        this.getTranslations();
    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate() {
        if (this.state.translations) {
            this.renderPhoneNumberErrorMessage();
            this.renderServiceErrorMessage();
            this.renderSubmitButton();
        }
    }
}

IdentityVerificationComponent.propTypes = {
    options: PropTypes.object.isRequired
};

export default IdentityVerificationComponent;
