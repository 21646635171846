const allFlowsContactInfoUpdateTemplate = `
<div ng-if="!isStateProfile">
  <div data-ng-if="saveStatusContact !== null" class="form-group has-error">
    <span class="help-block"> {{ saveStatusContact | translate:saveStatusParams}}
    </span>
  </div>

  <div class="form-group" data-ng-class="getFormGroupClass('email')">
    <label for="emailId" class="control-label">
      {{ "profile.personal.emailAddress" | translate }}
    </label>
    <input 
      type="text" 
      name="email" 
      id="emailId" 
      class="form-control" 
      id="personalEmailAddressId"
      data-ng-model="contactDetails.personalEmailAddress" 
      data-ng-required="true" 
      maxlength="80"
      ng-blur="usernameChange();removeInputFocus($event);" 
      ng-focus="setInputFocus($event)" 
      data-email-validator 
    />

    <ng-messages role="alert" for="getFieldError('email');" data-ng-if="isDirty('email');">
      <ng-message when="required">{{"emailRequired" | translate }}</ng-message>
      <ng-message when="emailFormat">{{"emailFormat" | translate }}</ng-message>
    </ng-messages>
    <span role="alert" class="help-block">{{emailError}}</span>
  </div>



  <div data-ng-if="phoneIsEmpty()" class="form-group has-error">
    <span id="phoneNumbererror" class="help-block"> {{ phoneNum_error | translate:saveStatusParams}}</span>
  </div>

  <div class="form-group" ng-if="contactDetails.domesticMobile!=null&&contactDetails.intlNumber!=null">
    <label for="phoneNumber" class="control-label">
      {{ "phone_number_1" | translate }}
    </label>
  </div>


  <div class="form-group" ng-if="contactDetails.domesticMobile!=null&&contactDetails.intlNumber===null">
    <label for="phoneNumber" class="control-label">
      {{ "phone_number_only" | translate }}
    </label>
  </div>

  <div class="row" ng-if="contactDetails.domesticMobile!=null">

    <div class="col-sm-6" ng-class="getFormGroupClass('country');"
      ng-if="contactDetails.domesticMobile!=null&&contactDetails.intlNumber===null">

      <select aria-label="{{ 'profile.personal.mobileContactCountryLabel' | translate}}" name="country"
        class="form-control" id="ContactCountryNameD" data-ng-model="contactDetails.domesticCntryCode"
        ng-change="populateSelectedCntryPhoneCode('ContactCountryNameD')">
        <option ng-repeat="option in contactDetails.countryDetails" value="{{option.countryCode}}{{option.countryPhNo}}"
          aria-label="{{option.countryName}}">
          {{option.countryName}}</option>
      </select>

      <ng-messages for="getFieldError('country');" ng-if="displayFieldMessages('country');" class="rule-validations">
        <ng-message when="required || isDirty('country')"> <span>{{"passwordRequired"
            | translate }}</span> </ng-message>
      </ng-messages>
      <span id="passwordError" class="help-block">{{passwordError}}</span>
    </div>


    <div class="col-sm-6" ng-class="getFormGroupClass('country');"
      ng-if="contactDetails.domesticMobile!=null&&contactDetails.intlNumber!=null">

      United States
    </div>

    <div class="col-sm-6" ng-if="contactDetails.domesticCntryCode ==='US1'"
      data-ng-class="getFormGroupClass('phoneNumberIdD')">
      <div class="input-group">
        <span class="input-group-addon" ng-class="getFormGroupClass('country');">{{selectedCntryPhCodeD}}</span>
        <input type="tel" name="phoneNumberIdD" id="phoneNumberIdD" class="form-control" data-ng-model="phoneNumberIdD"
          ng-model-options="{ updateOn: 'blur', allowInvalid: true }" ui-mask="(999) 999-9999"
          ui-options="{clearOnBlur:false, allowInvalidValue: true, eventsToHandle: ['blur', 'click', 'focus', 'keyup']}"
          ng-if="contactDetails.domesticCntryCode ==='US1'" model-view-value="false" placeholder="(###) ###-####"
          ui-mask-placeholder="" ui-mask-placeholder-char="_" data-phone-number-numeric-validator
          data-phone-number-validator />
      </div>

      <ng-messages for="getFieldError('phoneNumberIdD');" data-ng-if="isDirty('phoneNumberIdD');">

        <ng-message when="phoneNumberNumeric">{{"phoneNumberNumeric" | translate }}</ng-message>
        <ng-message when="phoneNumberDigits">{{"phoneNumberTenDigits" | translate }}</ng-message>
      </ng-messages>

    </div>

    <div class="col-sm-6" ng-if="contactDetails.domesticCntryCode !='US1'"
      data-ng-class="getFormGroupClass('intlNumberIdD')">
      <div class="input-group">
        <span class="input-group-addon" ng-class="getFormGroupClass('country');">{{selectedCntryPhCodeD}}</span>
        <input type="tel" name="intlNumberIdD" class="form-control" id="intlNumberIdD" maxlength="20"
          data-ng-model="intlNumberIdD" ui-options="{clearOnBlur:false, allowInvalidValue: true}" data-phone-number-only
          ng-if="contactDetails.domesticCntryCode!='US1'" model-view-value="false" />

      </div>

      <ng-messages for="getFieldError('intlNumberIdD');" data-ng-if="isDirty('intlNumberIdD');">
        <ng-message when="required">{{"phoneNumberRequired" | translate }}</ng-message>

      </ng-messages>

    </div>

    <span id="phoneNumberError" class="help-block">{{phoneNumberError}}</span>
  </div>

  <div class="form-group" ng-if="contactDetails.domesticMobile!=null && contactDetails.intlNumber!=null">
    <label for="phoneNumber" class="control-label">
      {{ "phone_number_2" | translate }}
    </label>
  </div>

  <div class="form-group" ng-if="contactDetails.domesticMobile===null && contactDetails.intlNumber!=null">
    <label for="phoneNumber" class="control-label">
      {{ "phone_number_only" | translate }}
    </label>
  </div>

  <div class="row" data-ng-class="getFormGroupClass('intlNumberIdD')" ng-if="contactDetails.intlNumber!=null">

    <div class="col-sm-6" ng-class="getFormGroupClass('country');">

      <select name="intCountry" class="form-control" id="ContactCountryNameI"
        data-ng-model="contactDetails.intCntryCode" ng-change="populateSelectedCntryPhoneCode('ContactCountryNameI')">
        <option ng-repeat="option in contactDetails.countryDetails" value="{{option.countryCode}}{{option.countryPhNo}}"
          aria-label="{{option.countryName}}">
          {{option.countryName}}</option>
      </select>
      <ng-messages for="getFieldError('country');" ng-if="displayFieldMessages('country');" class="rule-validations">
        <ng-message when="required || isDirty('country')" class="form-validation-rule">
          <span>{{"passwordRequired" | translate }}</span>
        </ng-message>
      </ng-messages>
      <span id="passwordError" class="help-block">{{passwordError}}</span>

    </div>

    <div class="col-sm-6" data-ng-class="getFormGroupClass('phoneNumberIdI')"
      ng-if="contactDetails.intCntryCode ==='US1'">

      <div class="input-group">
        <span class="input-group-addon" ng-class="getFormGroupClass('country');">{{selectedCntryPhCodeI}}</span>
        <input type="tel" name="phoneNumberIdI" id="phoneNumberIdI" class="form-control" data-ng-model="phoneNumberIdI"
          ng-model-options="{ updateOn: 'blur', allowInvalid: true }" ui-mask="(999) 999-9999"
          ui-options="{clearOnBlur:false, allowInvalidValue: true, eventsToHandle: ['blur', 'click', 'focus', 'keyup']}"
          ng-if="contactDetails.intCntryCode ==='US1'" model-view-value="false" placeholder="(###) ###-####" ui-mask-placeholder=""
          ui-mask-placeholder-char="_" data-phone-number-numeric-validator data-phone-number-validator />
      </div>
      <ng-messages for="getFieldError('phoneNumberIdI');" data-ng-if="isDirty('phoneNumberIdI');">
        <ng-message when="phoneNumberNumeric">{{"phoneNumberNumeric" | translate }}</ng-message>
        <ng-message when="phoneNumberDigits">{{"phoneNumberTenDigits" | translate }}</ng-message>
      </ng-messages>
      <span id="phoneNumberError" class="help-block">{{phoneNumberError}}</span>

    </div>

    <div class="col-sm-6" data-ng-class="getFormGroupClass('intlNumberIdI')"
      ng-if="contactDetails.intCntryCode !='US1'">
      <div class="input-group">
        <span class="input-group-addon" ng-class="getFormGroupClass('country');">{{selectedCntryPhCodeI}}</span>
        <input type="tel" name="intlNumberIdI" class="form-control" id="intlNumberIdI" data-ng-model="intlNumberIdI"
          ui-options="{clearOnBlur:false, allowInvalidValue: true}" data-phone-number-only maxlength="20"
          ng-if="contactDetails.intCntryCode!='US1'" model-view-value="false" />
      </div>

      <ng-messages for="getFieldError('intlNumberIdI');" data-ng-if="isDirty('intlNumberIdI');">
        <ng-message when="required">{{"phoneNumberRequired" | translate }}</ng-message>

      </ng-messages>
      <span id="phoneNumberError" class="help-block">{{phoneNumberError}}</span>


    </div>
  </div>
</div>
<!-- BEGIN: Profile state -->
<div ng-if="isStateProfile">
  <div data-ng-if="saveStatusContact !== null" class="form-group has-error">
    <span class="help-block">{{ saveStatusContact | translate:saveStatusParams }}</span>
  </div>
  <!-- BEGIN: Email -->
  <div class="form-group" data-ng-class="getFormGroupClass('email')">
    <label for="personalEmailId" class="control-label">{{ "profile.personal.emailAddress" | translate }}</label>
    <input type="text" name="email" id="personalEmailId" class="form-control"
      data-ng-model="contactDetails.personalEmailAddress" data-ng-required="true" maxlength="80"
      ng-blur="usernameChange();removeInputFocus($event);" ng-focus="setInputFocus($event)"
      aria-describedby="emailErrorList emailError" data-email-validator />
    <ng-messages id="emailErrorList" for="getFieldError('email');" data-ng-if="isDirty('email');">
      <ng-message when="required">{{ "emailRequired" | translate }}</ng-message>
      <ng-message when="emailFormat">{{ "emailFormat" | translate }}</ng-message>
    </ng-messages>
    <span id="emailError" class="help-block">{{ emailError }}</span>
  </div>
  <!-- END: Email-->
  <!-- BEGIN: Mobile/Domestic phone number -->
  <div data-ng-if="phoneIsEmpty()" class="form-group has-error">

    <span id="phoneNumbererror" class="help-block">{{ phoneNum_error | translate:saveStatusParams }}</span>
  </div>
  <div>
    <div class="form-group">
      <label for="phoneNumberIdD" class="control-label">{{ "profile.personal.mobileNumber" | translate }}</label>
    </div>
    <div class="row">
      <div class="col-sm-6" ng-class="getFormGroupClass('country');">
        <select aria-label="{{ 'profile.personal.mobileContactCountryLabel' | translate}}" name="country"
          class="form-control" id="ContactCountryNameD" data-ng-model="contactDetails.domesticCntryCode"
          ng-change="populateSelectedCntryPhoneCode('ContactCountryNameD')" ng-disabled="true">
          <option ng-repeat="option in contactDetails.countryDetails"
            value="{{option.countryCode}}{{option.countryPhNo}}" aria-label="{{option.countryName}}">{{
            option.countryName }}</option>
          <option value="US1" aria-label="United States" data-ng-if="contactDetails.intCntryCode">UNITED STATES</option>
        </select>
      </div>
      <div class="col-sm-6" data-ng-class="getFormGroupClass('phoneNumberIdD')">
        <div class="input-group">
          <span class="input-group-addon" ng-class="getFormGroupClass('country');">{{ selectedCntryPhCodeD }}</span>
          <input type="tel" name="phoneNumberIdD" id="phoneNumberIdD" class="form-control"
            data-ng-model="phoneNumberIdD" ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
            ui-mask="(999) 999-9999"
            placeholder="(###) ###-####"
            ui-options="{clearOnBlur:false, allowInvalidValue: true, eventsToHandle: ['blur', 'click', 'focus', 'keyup']}"
            data-phone-number-numeric-validator data-phone-number-validator />
        </div>
        <ng-messages for="getFieldError('phoneNumberIdD');" data-ng-if="isDirty('phoneNumberIdD');">
          <ng-message when="phoneNumberNumeric">{{ "phoneNumberNumeric" | translate }}</ng-message>
          <ng-message when="phoneNumberDigits">{{ "phoneNumberTenDigits" | translate }}</ng-message>
        </ng-messages>
      </div>
    </div>
  </div>
  <!-- END: Mobile/Domestic phone number -->
  <!-- BEGIN: Home phone number -->
  <div ng-if="contactDetails.home" class="margin-top-100">
    <div class="form-group">
      <label for="homeNumberId" class="control-label">{{ "profile.personal.home" | translate }}</label>
    </div>
    <div class="row">
      <div class="col-sm-6" ng-class="getFormGroupClass('country');">
        <select aria-label="{{ 'profile.personal.homeContactCountryLabel' | translate}}" name="country"
          class="form-control" id="ContactCountryNameH" data-ng-model="contactDetails.homeCntryCode"
          ng-change="populateSelectedCntryPhoneCode('ContactCountryNameH')" ng-disabled="true">
          <option ng-repeat="option in contactDetails.countryDetails"
            value="{{option.countryCode}}{{option.countryPhNo}}" aria-label="{{option.countryName}}">{{
            option.countryName }}</option>
          <option value="US1" aria-label="United States" data-ng-if="contactDetails.intCntryCode">UNITED STATES</option>
        </select>
      </div>
      <div class="col-sm-6" data-ng-class="getFormGroupClass('homeNumberId')">
        <div class="input-group">
          <span class="input-group-addon" ng-class="getFormGroupClass('country');">{{ selectedCntryPhCodeH }}</span>
          <input type="tel" name="homeNumberId" id="homeNumberId" class="form-control" data-ng-model="homeNumberId"
            ng-model-options="{ updateOn: 'blur', allowInvalid: true }" ui-mask="(999) 999-9999"
            ui-options="{clearOnBlur:false, allowInvalidValue: true, eventsToHandle: ['blur', 'click', 'focus', 'keyup']}"
            model-view-value="false" placeholder="(###) ###-####" ui-mask-placeholder="" ui-mask-placeholder-char="_"
            data-phone-number-numeric-validator data-phone-number-validator />
        </div>
        <ng-messages for="getFieldError('homeNumberId');" data-ng-if="isDirty('homeNumberId');">
          <ng-message when="phoneNumberNumeric">{{ "phoneNumberNumeric" | translate }}</ng-message>
          <ng-message when="phoneNumberDigits">{{ "phoneNumberTenDigits" | translate }}</ng-message>
        </ng-messages>
      </div>
    </div>
  </div>
  <!-- END: Home phone number -->
  <!-- BEGIN: International phone number -->
  <div ng-if="contactDetails.intlNumber" class="margin-top-100">
    <div class="form-group">
      <label for="intlNumberIdI" class="control-label">{{ "profile.personal.intlNumber" | translate }}</label>
    </div>
    <div class="row">
      <div class="col-sm-6" ng-class="getFormGroupClass('country');">
        <select aria-label="{{ 'profile.personal.intlContactCountryLabel' | translate}}" name="country"
          class="form-control" id="ContactCountryNameI" data-ng-model="contactDetails.intCntryCode"
          ng-change="populateSelectedCntryPhoneCode('ContactCountryNameI')">
          <option ng-repeat="option in countryDetailsIntl" value="{{option.countryCode}}{{option.countryPhNo}}"
            aria-label="{{option.countryName}}">{{ option.countryName }}</option>
        </select>
      </div>
      <div class="col-sm-6" data-ng-class="getFormGroupClass('intlNumberIdI')">
        <div class="input-group">
          <span class="input-group-addon" ng-class="getFormGroupClass('country');">{{ selectedCntryPhCodeI }}</span>
          <input type="tel" name="intlNumberIdI" id="intlNumberIdI" class="form-control" data-ng-model="intlNumberIdI"
            ng-model-options="{ updateOn: 'blur', allowInvalid: true }" maxlength="20"
            ui-options="{clearOnBlur:false, allowInvalidValue: true}" model-view-value="false" 
            data-phone-number-numeric-validator data-phone-number-validator />
        </div>
        <ng-messages for="getFieldError('intlNumberIdI');" data-ng-if="isDirty('intlNumberIdI')">
          <ng-message when="phoneNumberNumeric">{{ "phoneNumberNumeric" | translate }}</ng-message>
          <ng-message when="phoneNumberDigits">{{ "phoneNumberTenDigits" | translate }}</ng-message>
        </ng-messages>
        <ng-messages for="getFieldError('intlNumberIdI');" data-ng-if="isDirty('intlNumberIdI')">
          <ng-message when="required">{{ "phoneNumberRequired" | translate }}</ng-message>
        </ng-messages>
      </div>
    </div>
  </div>
  <!-- END: International phone number -->
  <!-- BEGIN: Add phone number anchors/hyperlinks -->
  <!-- Condition: If only a mobile phone number currently displays, the content should be "Add home or international phone number". -->
  <p ng-if="contactDetails.domesticMobile && !contactDetails.home && !contactDetails.intlNumber" class="margin-top-100">
    <a tabIndex="0" ng-keydown="handleKeydownAddNewPhoneNumber($event, 'homeOrIntl')" role="button"
      ng-click="addNewPhoneNumber('homeOrIntl')" id="homeOrIntlAnchor">{{
      "profile.contactInfoUpdate.addPhoneNumberAnchors.icon" | translate }} {{
      "profile.contactInfoUpdate.addPhoneNumberAnchors.homeOrIntl" | translate}}</a>
  </p>
  <!-- Condition: If only a home phone number currently displays, the content should be "Add mobile or international phone number". -->
  <p ng-if="contactDetails.home && !contactDetails.domesticMobile && !contactDetails.intlNumber" class="margin-top-100">
    <a tabIndex="0" ng-keydown="handleKeydownAddNewPhoneNumber($event, 'mobileOrIntl')" role="button"
      ng-click="addNewPhoneNumber('mobileOrIntl')" id="mobileOrIntlAnchor">{{
      "profile.contactInfoUpdate.addPhoneNumberAnchors.icon" | translate }} {{
      "profile.contactInfoUpdate.addPhoneNumberAnchors.mobileOrIntl" | translate}}</a>
  </p>
  <!-- Condition: If only an international phone number currently displays, the content should be "Add mobile or home phone number". -->
  <p ng-if="contactDetails.intlNumber && !contactDetails.domesticMobile && !contactDetails.home" class="margin-top-100">
    <a tabIndex="0" ng-keydown="handleKeydownAddNewPhoneNumber($event, 'mobileOrHome')" role="button"
      ng-click="addNewPhoneNumber('mobileOrHome')" id="mobileOrHomeAnchor">{{
      "profile.contactInfoUpdate.addPhoneNumberAnchors.icon" | translate }} {{
      "profile.contactInfoUpdate.addPhoneNumberAnchors.mobileOrHome" | translate}}</a>
  </p>
  <!-- Condition: If a mobile and home number currently display, the content should be "Add an international phone number". -->
  <p ng-if="contactDetails.domesticMobile && contactDetails.home && !contactDetails.intlNumber" class="margin-top-100">
    <a tabIndex="0" ng-keydown="handleKeydownAddNewPhoneNumber($event, 'intl')" role="button"
      ng-click="addNewPhoneNumber('intl')" id="intlAnchor">{{ "profile.contactInfoUpdate.addPhoneNumberAnchors.icon" |
      translate }} {{ "profile.contactInfoUpdate.addPhoneNumberAnchors.intl" | translate}}</a>
  </p>
  <!-- Condition: If a mobile and international number currently display, the content should be "Add a home phone number". -->
  <p ng-if="contactDetails.domesticMobile && contactDetails.intlNumber && !contactDetails.home" class="margin-top-100">
    <a tabIndex="0" ng-keydown="handleKeydownAddNewPhoneNumber($event, 'home')" role="button"
      ng-click="addNewPhoneNumber('home')" id="homeAnchor">{{ "profile.contactInfoUpdate.addPhoneNumberAnchors.icon" |
      translate }} {{ "profile.contactInfoUpdate.addPhoneNumberAnchors.home" | translate}}</a>
  </p>
  <!-- Condition: If a home and international number currently display, the content should be "Add a mobile phone number". -->
  <p ng-if="contactDetails.home && contactDetails.intlNumber && !contactDetails.domesticMobile" class="margin-top-100">
    <a tabIndex="0" ng-keydown="handleKeydownAddNewPhoneNumber($event, 'mobile')" role="button"
      ng-click="addNewPhoneNumber('mobile')" id="mobileAnchor">{{ "profile.contactInfoUpdate.addPhoneNumberAnchors.icon"
      | translate }} {{ "profile.contactInfoUpdate.addPhoneNumberAnchors.mobile" | translate}}</a>
  </p>
  <!-- END: Add phone number anchors/hyperlinks -->
  <!-- BEGIN: Additional mobile phone number field -->
  <div ng-if="hasAddMobilePhoneNumberAnchorBeenClicked" class="margin-top-100">
    <div class="form-group">
      <label for="phoneNumberIdD" class="control-label">{{ "profile.personal.mobileNumber" | translate }}</label>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <select aria-label="{{ 'profile.personal.mobileContactCountryLabel' | translate}}" name="country"
          class="form-control" id="ContactCountryNameD" data-ng-model="contactDetails.domesticCntryCode"
          ng-change="populateSelectedCntryPhoneCode('ContactCountryNameD')" ng-disabled="true">
          <option ng-repeat="option in contactDetails.countryDetails"
            value="{{option.countryCode}}{{option.countryPhNo}}" aria-label="{{option.countryName}}">{{
            option.countryName }}</option>
          <option value="US1" aria-label="United States" data-ng-if="contactDetails.intCntryCode">UNITED STATES</option>
        </select>
      </div>
      <div class="col-sm-6" ng-class="getFormGroupClass('phoneNumberIdD');">
        <div class="input-group">
          <span class="input-group-addon" ng-class="getFormGroupClass('country');">{{ selectedCntryPhCodeD }}</span>
          <input type="tel" name="phoneNumberIdD" id="phoneNumberIdD" class="form-control"
            data-ng-model="phoneNumberIdD" ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
            ui-mask="(999) 999-9999"
            ui-options="{clearOnBlur:false, allowInvalidValue: true, eventsToHandle: ['blur', 'click', 'focus', 'keyup']}"
            model-view-value="false" placeholder="(###) ###-####" ui-mask-placeholder="" ui-mask-placeholder-char="_"
            data-phone-number-numeric-validator data-phone-number-validator />
        </div>
        <ng-messages for="getFieldError('phoneNumberIdD');" data-ng-if="isDirty('phoneNumberIdD');">
          <ng-message when="phoneNumberNumeric">{{ "phoneNumberNumeric" | translate }}</ng-message>
          <ng-message when="phoneNumberDigits">{{ "phoneNumberTenDigits" | translate }}</ng-message>
        </ng-messages>
      </div>
    </div>
  </div>
  <!-- END: Additional mobile phone number field -->
  <!-- BEGIN: Additional home phone number field -->
  <div ng-if="hasAddHomePhoneNumberAnchorBeenClicked" class="margin-top-100">
    <div class="form-group">
      <label for="homeNumberId" class="control-label">{{ "profile.personal.home" | translate }}</label>
    </div>
    <div class="row">
      <div class="col-sm-6" ng-class="getFormGroupClass('country');">
        <select aria-label="{{ 'profile.personal.homeContactCountryLabel' | translate}}" name="country"
          class="form-control" id="ContactCountryNameH" data-ng-model="contactDetails.homeCntryCode"
          ng-change="populateSelectedCntryPhoneCode('ContactCountryNameH')" ng-disabled="true">
          <option ng-repeat="option in contactDetails.countryDetails"
            value="{{option.countryCode}}{{option.countryPhNo}}" aria-label="{{option.countryName}}">{{
            option.countryName }}</option>
          <option value="US1" aria-label="United States" data-ng-if="contactDetails.intCntryCode">UNITED STATES</option>
        </select>
      </div>
      <div class="col-sm-6" data-ng-class="getFormGroupClass('homeNumberId')">
        <div class="input-group">
          <span class="input-group-addon" ng-class="getFormGroupClass('country');">{{ selectedCntryPhCodeH }}</span>
          <input type="tel" name="homeNumberId" id="homeNumberId" class="form-control" data-ng-model="homeNumberId"
            ng-model-options="{ updateOn: 'blur', allowInvalid: true }" ui-mask="(999) 999-9999"
            ui-options="{clearOnBlur:false, allowInvalidValue: true, eventsToHandle: ['blur', 'click', 'focus', 'keyup']}"
            model-view-value="false" placeholder="(###) ###-####" ui-mask-placeholder="" ui-mask-placeholder-char="_"
            data-phone-number-numeric-validator data-phone-number-validator />
        </div>
        <ng-messages for="getFieldError('homeNumberId');" data-ng-if="isDirty('homeNumberId');">
          <ng-message when="phoneNumberNumeric">{{ "phoneNumberNumeric" | translate }}</ng-message>
          <ng-message when="phoneNumberDigits">{{ "phoneNumberTenDigits" | translate }}</ng-message>
        </ng-messages>
      </div>
    </div>
  </div>
  <!-- END: Additional home phone number field -->
  <!-- BEGIN: Additional international phone number field -->
  <div ng-if="hasAddIntlPhoneNumberAnchorBeenClicked" class="margin-top-100">
    <div class="form-group">
      <label for="intlNumberIdI" class="control-label">{{ "profile.personal.intlNumber" | translate }}</label>
    </div>
    <div class="row">
      <div class="col-sm-6" ng-class="getFormGroupClass('country');">
        <select aria-label="{{ 'profile.personal.intlContactCountryLabel' | translate}}" name="country"
          class="form-control" id="ContactCountryNameI" data-ng-model="contactDetails.intCntryCode"
          ng-change="populateSelectedCntryPhoneCode('ContactCountryNameI')">
          <option ng-repeat="option in countryDetailsIntl" value="{{option.countryCode}}{{option.countryPhNo}}"
            aria-label="{{option.countryName}}">{{ option.countryName }}</option>
        </select>
      </div>
      <div class="col-sm-6" data-ng-class="getFormGroupClass('intlNumberIdI')">
        <div class="input-group">
          <span class="input-group-addon" ng-class="getFormGroupClass('country');">{{ selectedCntryPhCodeI }}</span>
          <input type="tel" name="intlNumberIdI" id="intlNumberIdI" class="form-control" data-ng-model="intlNumberIdI"
            ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
            maxlength="20"  ui-options="{clearOnBlur:false, allowInvalidValue: true}" model-view-value="false" 
            data-phone-number-numeric-validator data-phone-number-validator />
        </div>
        <ng-messages for="getFieldError('intlNumberIdI');" data-ng-if="isDirty('intlNumberIdI')">
          <ng-message when="phoneNumberNumeric">{{ "phoneNumberNumeric" | translate }}</ng-message>
          <ng-message when="phoneNumberDigits">{{ "phoneNumberTenDigits" | translate }}</ng-message>
        </ng-messages>
        <ng-messages for="getFieldError('intlNumberIdI');" data-ng-if="isDirty('intlNumberIdI')">
          <ng-message when="required">{{ "phoneNumberRequired" | translate }}</ng-message>
        </ng-messages>
      </div>
    </div>
  </div>
  <!-- END: Additional international phone number field -->
</div>
<!-- END: Profile state -->
`;
export default allFlowsContactInfoUpdateTemplate;
