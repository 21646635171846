const oauthController = function ($rootScope, $scope, oauthFactory) {
    //$rootScope.$state = $state;
    let grantScopeArr = [];
    $scope.oauth = {};
    $scope.flowName = $rootScope.flowName;

    $scope.getLginURL = function () {
        $scope.flowName = $rootScope.flowName;

        $scope.getDeliveryParams = {
            flowName: $scope.flowName
        };

        oauthFactory.getLginURL.query(
            function (data) {
                console.log("ch_URL" + oauthFactory.getLginURL);
                $scope.oauth = data;
                console.log("url:", data);
                const Url = data.state;
                console.log("Url:" + Url);
                const accucode = Url.substring(Url.indexOf("?") + 1);
                window.location.href = "/participant/#/oauth/login?" + accucode;
            }, //When it fails
            function (response) {
                $scope.oauth.errorMessage = "";
                if (response.headers("exception") !== null) {
                    $scope.oauth.errorMessage = response.data.error.code;
                    $scope.oauth.errorMessageParams = response.data.error.errors[0];
                } else {
                    $scope.oauth.errorMessage = "99999";
                }
            }
        ); //End of query function
    };
    $scope.getConsentURL = function () {
        $scope.flowName = $rootScope.flowName;
        $scope.getDeliveryParams = {
            flowName: $scope.flowName
        };
        oauthFactory.getConsentURL.query(
            function (data) {
                $scope.oauth = data;
                $scope.GrantScopes = [
                    {
                        Value: $scope.oauth.grantScopes[1],
                        Name: "Access to your Empower accounts general information, and read access to transactions history",
                        Selected: false
                    }
                ];
            },
            function (response) {
                $scope.oauth.errorMessage = "";
                if (response.headers("exception") !== null) {
                    $scope.oauth.errorMessage = response.data.error.code;
                    $scope.oauth.errorMessageParams = response.data.error.errors[0];
                } else {
                    $scope.oauth.errorMessage = "99999";
                }
            }
        );

        $scope.rejectConsent = function () {
            const consentChallenge = {
                challenge: $scope.challenge
            };
            oauthFactory.rejectConsent.query(
                consentChallenge,
                //success
                function (data) {
                    $scope.showSpinner = false;
                    $scope.oauth = data;
                    window.location.href = $scope.oauth.redirectTo;
                },
                //When it fails
                function (response) {
                    console.log("fail", consentChallenge, response);
                }
            );
        }; //End of rejectConsent function
        $scope.acceptConsent = function () {
            grantScopeArr = ["offline", "fdx.accounts.read"];
            console.log(grantScopeArr);

            const consentData = {
                grantAccessTokenAudience: ["null"],
                grantScopes: grantScopeArr,
                challenge: $scope.oauth.challenge,
                client: $scope.oauth.clientName,
                remember: false
            };
            oauthFactory.acceptConsent.query(
                consentData,
                //success
                function (data) {
                    $scope.oauth = data;
                    console.log($scope.oauth.redirectTo);
                    window.location.assign($scope.oauth.redirectTo, true);
                    return true;
                },
                //When it fails
                function (response) {
                    console.log("fail", consentData, response);
                }
            );
        }; //End of acceptConsent function
    };
};

oauthController.$inject = ["$rootScope", "$scope", "oauthFactory", "$state"];
export default oauthController;
