import ReferenceData from "../../constants/ReferenceData";
import { executePost } from "../../utils/appUtils";

interface VerifyCodeByIndIdDbNamePayload {
    indIdDbName: string;
    verificationCode: string;
}

interface VerifyCodeByIndIdDbNameResponseData {
    destinationUrl: string;
    state: string;
}

type VerifyCodeByIndIdDbNameSuccessResponse = VerifyCodeByIndIdDbNameResponseData | undefined;

const verifyCodeByIndIdDbName = (payload: VerifyCodeByIndIdDbNamePayload) => {
    const verifyCodeByIndIdDbNameSuccess = (results): VerifyCodeByIndIdDbNameSuccessResponse => {
        if (results?.data) {
            return results.data;
        }
    };

    return executePost(
        ReferenceData.VERIFY_CODE_BY_IND_ID_DB_NAME,
        "verifyCodeByIndIdDbNameFault",
        payload
    )
        .then(verifyCodeByIndIdDbNameSuccess)
        .catch((error) => {
            throw error;
        });
};

export default verifyCodeByIndIdDbName;
