import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

const IndIdSelectionController = function (
    $cookies,
    $scope,
    $stateParams,
    $translate,
    IndIdSelectionFactory,
    SetACCUService,
    accountService,
    redirectService
) {
    const vm = this;
    const logger = ExternalLogger.getInstance("IndIdSelectionController");
    vm.loading = false;
    vm.doSetAccu = false; /* don't set accu upon indId selection */
    vm.isDeeplink = $stateParams.deeplink;

    vm.init = function () {
        vm.loading = true;
        if (vm.isDeeplink) {
            $scope.plansMapWithIndId = $stateParams.deeplink.plansMapWithIndid;
        } else {
            IndIdSelectionFactory.retrievePlansWithIndid.query({}, function (data) {
                $scope.plansMapWithIndId = data.plansMapWithIndid;
            });
        }
        vm.loading = false;
    };

    vm.selectIndId = function (individualId) {
        vm.loading = true;
        vm.error = false;
        const funcName = "selectIndId: ";
        logger.debug(funcName + "Selected IndId [" + individualId + "]");

        vm.selectIndId = function (individualId) {
            vm.loading = true;
            vm.error = false;
            const funcName = "selectIndId: ";
            logger.debug(funcName + "Selected IndId [" + individualId + "]");
            const params = {
                individualId: individualId
            };
            if (vm.isDeeplink) {
                const plan_selected = $scope.plansMapWithIndId[individualId][0];
                const indId = plan_selected.indId;
                const ga_id = plan_selected.gaId;
                let destination_url = $stateParams.deeplink.deepLinkedUrl;

                const search = window.location.search;
                if (search !== "") {
                    const deepLinkSearch = search + "#";
                    destination_url = destination_url.replace("#", deepLinkSearch);
                }

                destination_url = destination_url.replace(":indId", indId);
                destination_url = destination_url.replace(":gaId", ga_id);
                destination_url = destination_url.replace(":groupId", ga_id);

                redirectService.redirect(
                    { destinationUrl: destination_url, isDeepLink: true },
                    "ALL"
                );
                vm.indIdSelected = true;
                vm.loading = false;
                vm.error = false;
            } else {
                IndIdSelectionFactory.selectIndId.query(
                    params,
                    //success
                    function (data) {
                        logger.debug(funcName + "Response data: {0}", [JSON.stringify(data)]);
                        if (data.status === "SUCCESSFUL") {
                            logger.debug("re-fetching primary plan & setting contact details..");
                            accountService.setPrimaryGroupAccount().then(function () {
                                const primaryPlan = accountService.getPrimaryGroupAccount();
                                accountService.setAccountDetails(primaryPlan);
                                const activeGroupAccount = {
                                    individualId: primaryPlan.individualId,
                                    groupId: primaryPlan.groupId
                                };
                                accountService.setSiteContactInfoForPlan(activeGroupAccount);
                            });

                            if (vm.doSetAccu) {
                                if (
                                    data.data.accessPortalGroups &&
                                    data.data.accessPortalGroups.length > 0
                                ) {
                                    const accuParam = data.data.accessPortalGroups[0].accu;
                                    logger.debug(funcName + "Setting customizations: [{0}]", [
                                        accuParam
                                    ]);
                                    //setAccu
                                    SetACCUService.query({ accu: accuParam }, function (accuData) {
                                        logger.info(
                                            funcName +
                                                "setAccu call completed...setting cookies...[{0}]. Response: {1}",
                                            [accuParam, JSON.stringify(accuData)]
                                        );
                                        $scope.accu = accuParam;
                                        //set cookie
                                        $cookies.remove("accu", { path: "/" });
                                        $cookies.remove("accu", { path: "/participant" });
                                        $cookies.remove("clientAccu", { path: "/" });
                                        $cookies.remove("clientAccu", { path: "/participant" });
                                        $cookies.remove("PM-ACCU-TOKEN", { path: "/" });

                                        $cookies.put("accu", accuParam, { path: "/participant" });
                                        $cookies.put("clientAccu", accuParam, {
                                            path: "/participant"
                                        });
                                        $cookies.put("PM-ACCU-TOKEN", accuParam, { path: "/" });

                                        logger.debug(funcName + "redirecting to landing page...");
                                        redirectService.redirect(data.data, "ALL");
                                        vm.indIdSelected = true;
                                        vm.loading = false;
                                        vm.error = false;
                                    });
                                } else {
                                    logger.error(
                                        funcName + "Response missing access portal groups: {0}",
                                        [JSON.stringify(data)]
                                    );
                                    vm.loading = false;
                                    vm.error = true;
                                }
                            } else {
                                redirectService.redirect(data.data, "ALL");
                                vm.indIdSelected = true;
                                vm.loading = false;
                                vm.error = false;
                            }
                        } else {
                            logger.error(funcName + "Non-Success Response: {0}", [
                                JSON.stringify(data)
                            ]);
                            vm.loading = false;
                            vm.error = true;
                        }
                    },
                    function () {
                        vm.loading = false;
                        vm.error = true;
                    }
                ); //End of query function
            }
        };

        vm.disableSelectButton = function () {
            return vm.loading || vm.indIdSelected;
        };

        $translate(["indIdSelection.title", "indIdSelection.intro", "button.view"]).then(
            function (translations) {
                // eslint-disable-next-line no-undef
                angular.extend($scope, {
                    translatedData: {
                        indIdSelectionTitle: translations["indIdSelection.title"],
                        indIdSelectionIntro: translations["indIdSelection.intro"],
                        buttonView: translations["button.view"]
                    }
                });
            }
        );

        vm.init();
    };
};

IndIdSelectionController.$inject = [
    "$cookies",
    "$scope",
    "$stateParams",
    "$translate",
    "IndIdSelectionFactory",
    "SetACCUService",
    "accountService",
    "redirectService"
];
export default IndIdSelectionController;
