export const DEFAULT_STALE_TIME = 300_000; // 5 minutes

export const QUERY_KEYS = {
    GET_AUTH_DATA: "getAuthData",
    GET_PSC_PATH: "getPSCPath",
    GET_DEFAULT_CUSTOM_RTR: "getDefaultCustomizationRetr",
    GET_DELIVERY_OPTIONS: "getDeliveryOptions",
    GET_PRE_LOGIN_ACCU_RETRIEVAL: "getPreLoginAccuRetrieval",
    SET_ACCOUNT_DETAILS: "setAccountDeatils",
    SET_ACCU_SERVICE: "setAccuService",
    GET_DETAILS: "retrieve-details"
};
