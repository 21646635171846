import { PerformanceTrackingKeys } from "core-ui/client/react/core/constants/constants";

import mfaEvents from "./events/MFAEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const mfaController = function ($rootScope, $scope, $state, $timeout, eventBus, mfaFactory) {
    $rootScope.$state = $state;
    $scope.mfa = {};
    $scope.activationCodeModel = {};
    $scope.emptyDeliveryOptionsMessage = null;
    $scope.internationalPhoneMessage = null;
    $scope.flowName = $rootScope.flowName;
    $scope.formOnError = false;

    extractErrorMessageFromRedirection(window.location.href);

    // this is used to track the the time from signin button clicked to
    // the MFA screen loading
    $scope.trackTimeLoad = function () {
        const signinTime = localStorage.getItem(PerformanceTrackingKeys.PT_SIGNIN_CLICKED);
        const mfaLoadedTime = new Date().getTime();
        localStorage.removeItem(PerformanceTrackingKeys.PT_MFA_LOADED);
        localStorage.setItem(PerformanceTrackingKeys.PT_MFA_LOADED, mfaLoadedTime);
        localStorage.setItem(
            PerformanceTrackingKeys.PT_SIGNIN_MFA_DELTA,
            mfaLoadedTime - signinTime
        );
    };
    // ViewModel replaces $scope

    $scope.getDeliveryOptions = function () {
        $scope.flowName = $rootScope.flowName;

        $scope.getDeliveryParams = {
            flowName: $scope.flowName
        };

        mfaFactory.getDeliveryOptions
            .query(
                $scope.getDeliveryParams,
                //success
                function (data, headers) {
                    $scope.authentication.flowName = data.flowName;
                    $scope.mfa.errorMessage = "";
                    $scope.mfa = data;
                    let hasIntlPhoneNumber = false;

                    if (headers("exception") !== null) {
                        $scope.mfa.errorMessage = headers("exception");
                    } else {
                        if (data.destination == "emptyDeliveryOptions") {
                            $scope.emptyDeliveryOptionsMessage =
                                "We are unable to complete your request. If you require immediate account access please contact a Participant Services Representative.";
                            $state.go("activationCodeDeliveryOptions");
                        } else if (!data.intlPhoneSupportAllowed) {
                            for (let i = 0; i < data.deliverySet.length; i++) {
                                if (data.deliverySet[i].deliveryPhoneType === "IntlPhoneNumber") {
                                    hasIntlPhoneNumber = true;
                                    $scope.internationalPhoneMessage =
                                        "International phone number support is currently unavailable.";
                                    $state.go("activationCodeDeliveryOptions");
                                }
                            }
                            if (!hasIntlPhoneNumber) {
                                data.destination = "mfa";
                            }
                        } else {
                            data.destination = "mfa";
                        }

                        $scope.mfa.dataLoaded = true;
                        //set focus on deliveryOption element
                        $timeout(function () {
                            // eslint-disable-next-line no-undef
                            document.getElementById("deliveryOption").focus();
                        }, 200);
                    }
                }, //When it fails
                function (response) {
                    $scope.mfa.errorMessage = "";
                    if (response.headers("exception") !== null) {
                        $scope.mfa.errorMessage = response.data.error.code;
                        $scope.mfa.errorMessageParams = response.data.error.errors[0];
                    } else {
                        // TBD - comment out code to test decoupled login logic
                        /**
                         * Not sure we need to set an error message but when a participant lands here
                         * they should be redirected back to the login page.
                         */
                        $scope.mfa.errorMessage = "99999";
                        // TODO(EOC): Should we always redirect here or only on no session...
                        /* if (response.data.error.code === "noSession") {
                            $state.go("login");
                        } */
                    }
                }
            )
            .$promise.finally(function () {
                // Check for errors
                $scope.formHasError();
            }); //End of query function
    }; //End of getDeliveryOptions function

    $scope.redirectToContactInfoFromMFA = function () {
        eventBus.dispatch(mfaEvents.CONTACT_REDIRECT, this);

        $state.go("contactUpdate");
    };

    $scope.createAndDeliverActivationCodePOST = function (functionName) {
        eventBus.dispatch(mfaEvents.SEND_CODE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: mfaEvents.SEND_CODE
            }
        });
        $scope.showSpinner = true;

        const mfaRequestData = {
            accu: $scope.accu,
            deliveryOption: $scope.activationCodeModel.deliveryOption
        };

        if ($scope.activationCodeModel.deliveryOption === "none") {
            $scope.mfa.errorMessage = "ActivationCodeDeliveryMissingInputException";
            $scope.showSpinner = false;
            // Check for errors
            $scope.formHasError();
        } else {
            mfaFactory.createAndDeliverActivationCodePOST
                .query(
                    mfaRequestData,
                    //success
                    function (data, headers) {
                        $scope.showSpinner = false;

                        if (headers("exception") !== null) {
                            $scope.mfa.errorMessage = headers("exception");
                        } else {
                            if (functionName === "mfa") {
                                $state.go("verifyCode");
                            } else if (functionName === "loginHelp") {
                                $state.go("verifyCodeForLoginHelp");
                            }
                        }
                    }, //When it fails
                    function (response) {
                        $scope.showSpinner = false;
                        if (response.headers("exception") !== null) {
                            $scope.mfa.errorMessage = response.data.error.code;
                            $scope.mfa.errorMessageParams = response.data.error.errors[0];
                        } else {
                            $scope.mfa.errorMessage = "99999";
                        }
                    }
                )
                .$promise.finally(function () {
                    // Check for errors
                    $scope.formHasError();
                }); //End of query function
        }
    }; //End of createAndSendActivationCodePOST function

    //--EVENTS----
    $scope.rememberDeviceClick = function () {
        eventBus.dispatch(mfaEvents.REMEMBER_DEVICE, this, {
            remember: $scope.verification.rememberDevice
        });
    };
    $scope.noCodeClick = function () {
        eventBus.dispatch(mfaEvents.NO_CODE, this);
    };
    $scope.signInClick = function () {
        eventBus.dispatch(mfaEvents.SIGN_IN, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: mfaEvents.SIGN_IN
            }
        });
    };
    $scope.activationCodeChange = function () {
        eventBus.dispatch(mfaEvents.CODE_CHANGE, this);
    };
    $scope.codeDeliveryChange = function (option) {
        if (option.indexOf("sms") == 0) {
            $scope.activationCodeModel.alert = "show";
        } else {
            $scope.activationCodeModel.alert = "no";
        }
        eventBus.dispatch(mfaEvents.CODE_DELIVERY_CHANGE, this, { option: option });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: mfaEvents.CODE_DELIVERY_CHANGE,
                option
            }
        });
    };
    $scope.verifyCode = function () {
        eventBus.dispatch(mfaEvents.VERIFY_CODE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: mfaEvents.VERIFY_CODE
            }
        });
    };
    $scope.verifyCodeLoginHelp = function () {
        eventBus.dispatch(mfaEvents.VERIFY_CODE_LOGIN_HELP, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: mfaEvents.VERIFY_CODE_LOGIN_HELP
            }
        });
    };
    $scope.sendCode = function () {
        eventBus.dispatch(mfaEvents.SEND_CODE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: mfaEvents.SEND_CODE
            }
        });
    };

    /**
     * Get form classes.
     * @returns by default 'form-group', on error 'form-group has-error'
     */
    $scope.getFormGroupClass = function () {
        return "form-group" + ($scope.formOnError ? " has-error" : "");
    };

    /**
     * Checks wheter form contains any error.
     * @returns true if form has error, false otherwise
     */
    $scope.formHasError = function () {
        return ($scope.formOnError =
            !!$scope.internationalPhoneMessage ||
            !!$scope.emptyDeliveryOptionsMessage ||
            !!$scope.mfa.errorMessage);
    };

    /**
     * Set $rootScope parameters for decoupled login page, deep link logic
     * @param {*} href
     */
    function extractErrorMessageFromRedirection(href) {
        if (String(href).includes("noScope")) {
            const start = String(href).indexOf("deepLinkParam") + 14;
            const deepLinkParam = String(href).substring(start);
            $rootScope.featureName = deepLinkParam;
            $rootScope.iframeDeepLink = true;
            $rootScope.flowName = "mfa";
            console.log("feature name", deepLinkParam);
        }
    }
};

mfaController.$inject = ["$rootScope", "$scope", "$state", "$timeout", "eventBus", "mfaFactory"];
export default mfaController;
