import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import _forEach from "lodash/forEach";
import _includes from "lodash/includes";
import _isArray from "lodash/isArray";
import _isUndefined from "lodash/isUndefined";
import _keys from "lodash/keys";
import _pull from "lodash/pull";

import profileEvents from "./events/ProfileEvents";

const ProfileCredentialsController = function (
    $modalInstance,
    $scope,
    $state,
    eventBus,
    profileFactory
) {
    $scope.profile = {
        username: profileFactory.getUsername(),
        password: "",
        newPassword: "",
        confirmPassword: ""
    };

    $scope.showSpinner = false;
    $scope.submitted = false;
    $scope.usernameFocus = false;
    $scope.passwordFocus = false;
    $scope.newPasswordFocus = false;

    /**
     * focus manager
     * indexed by fieldName
     * @type {{}}
     */
    $scope.focusMgr = {};

    $scope.forceValidations = function (inputs) {
        inputs.forEach(function (input) {
            $scope.credentialForm[input].$touched = true;
            $scope.credentialForm[input].$dirty = true;
        });
    };

    $scope.getFormGroupClass = function (fieldName) {
        if (_isUndefined($scope.credentialForm)) {
            return "";
        }
        const field = $scope.credentialForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field && field.$invalid && field.$dirty && !fieldFocused) {
            status = "has-error";
        }

        if (field && field.$valid && field.$dirty) {
            status = "has-success";
        }
        return status;
    };

    $scope.fieldOnBlur = function (fieldName) {
        const field = $scope.credentialForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field.$touched && field.$invalid && !fieldFocused) {
            status = "has-error";
        }
        return status;
    };

    $scope.getValidField = function (fieldName) {
        const field = $scope.credentialForm[fieldName].$valid;

        return field;
    };

    $scope.displayValidMessage = function (fieldName) {
        const field = $scope.credentialForm[fieldName];
        let displayValidMessage = false;

        if (field.$dirty && field.$valid) {
            displayValidMessage = true;
        }

        return displayValidMessage;
    };

    $scope.isTouched = function (fieldName) {
        //return $scope.accountSetupForm[fieldName].$touched;
        return $scope.credentialForm[fieldName].$dirty;
    };

    $scope.isDirty = function (fieldName) {
        const dirty = $scope.credentialForm[fieldName].$dirty;

        return dirty;
    };

    $scope.displayFieldMessages = function (fieldName) {
        if (_isUndefined($scope.credentialForm)) {
            return "";
        }
        const field = $scope.credentialForm[fieldName];
        if (_isUndefined($scope.credentialForm) || !field) {
            return true;
        }
        //var conditions = (field.$invalid && field.$dirty) || (field.$invalid && $scope.registerForm.$submitted) || field.$touched || !field.$pristine;
        const conditions = (field.$invalid && field.$dirty) || field.$touched || !field.$pristine;

        return conditions;
    };

    $scope.getFieldError = function (fieldName) {
        if (
            _isUndefined($scope.credentialForm) ||
            !fieldName ||
            _isUndefined($scope.credentialForm[fieldName])
        ) {
            return "";
        }
        return $scope.credentialForm[fieldName].$error;
    };

    /**
     * returns true if "two" is in "one".
     */
    function includesAny(one, two) {
        let rc = false;
        //console.log('includesAny',one, two);
        if (one && two && _isArray(one)) {
            if (_isArray(two)) {
                _forEach(two, function (value) {
                    if (_includes(one, value)) {
                        //console.log('contains!',value,one);
                        rc = true;
                    }
                });
            } else {
                //console.log('contains!',two,one);
                rc = _includes(one, two);
            }
        }
        return rc;
    }

    /**
     * remove "two" from "one".
     * _pullAll(..) wasn't working
     */
    function pullAll(one, two) {
        if (one && two && _isArray(one)) {
            if (_isArray(two)) {
                _forEach(two, function (value) {
                    //console.log('removing ',value,'from',one);
                    _pull(one, value);
                });
            } else {
                //console.log('removing ',two,'from',one);
                _pull(one, two);
            }
        }
        return one;
    }

    /**
     * determine the validation error (style) class.
     * different class if one error vs multiples.
     */
    $scope.getValidationErrorClass = function (fieldName) {
        let styleName = "rule-validations";

        if (
            _isUndefined($scope.credentialForm) ||
            !fieldName ||
            _isUndefined($scope.credentialForm[fieldName])
        ) {
            return styleName;
        }
        const errs = $scope.credentialForm[fieldName].$error;
        let errorKeys = _keys(errs);

        if (fieldName === "confirmPassword") {
            //console.log('errorKeys',fieldName, errorKeys);
            const otherErrors = [
                "minLength",
                "numberPattern",
                "uppercasePattern",
                "specialCharPattern",
                "meetCondition1"
            ];
            if (includesAny(errorKeys, otherErrors)) {
                errorKeys = pullAll(errorKeys, otherErrors);
                //errorKeys.push("otherErrors");
            }
            //console.log('errorKeys after',fieldName, errorKeys);
        }
        const errorCnt = errorKeys.length;

        //if >1 errors, the style class should be bulleted.
        if (errorCnt > 1) {
            styleName = "form-validation-rule";
        }

        return styleName;
    };

    $scope.removeInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = false;
    };

    $scope.setInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = true;
        $scope.credentialForm[event.target.name].$setTouched(true);
        $scope.credentialForm[event.target.name].$setDirty(true);
    };

    /**
     *  Close modal window
     **/
    $scope.cancel = function (source) {
        eventBus.dispatch(profileEvents.CANCEL, this, { source: source });
        $modalInstance.dismiss("cancel");
    };

    //isValid: is a variable passed by react if the form does not exist as angular anymore
    $scope.updateUserName = function (flowName, isValid, value) {
        // if input validation fails, redirect focus to the username input and NVDA read all input rules again
        $scope.setFocusToFirstInputElement();

        if (($scope.credentialForm && $scope.credentialForm.$valid) || isValid) {
            $scope.showSpinner = true;
            if (isValid && value) {
                //tehn data commign from react side
                $scope.profile.username = value;
            }
            eventBus.dispatch(profileEvents.UPDATE_USERNAME_VALID, this);
            const updateUserNameData = {
                username: $scope.profile.username,
                currentpassword: "NOT_NEEDED",
                newpassword: "NOT_NEEDED",
                flowName: flowName
            };

            profileFactory.updateCredentials.query(
                updateUserNameData,
                function () {
                    $modalInstance.dismiss("cancel");
                    $state.go(
                        "profile",
                        {},
                        {
                            reload: true
                        }
                    );
                },

                function (response) {
                    $scope.showSpinner = false;
                    if (response === "44403") {
                        $modalInstance.dismiss("cancel");
                    }
                    if (
                        response.headers("exception") !== null ||
                        response.headers("exceptionMessage") !== null
                    ) {
                        $scope.saveStatus = response.data.error.code;
                        $scope.saveStatusParams = response.data.error.errors[0];
                        $scope.setFocusToFirstInputElement("#usernameId", $scope.saveStatus);
                    }
                }
            );
        } else {
            eventBus.dispatch(profileEvents.UPDATE_USERNAME_INVALID, this);
            const inputs = ["password"];

            if ($scope.credentialForm) {
                inputs.forEach(function (input) {
                    $scope.credentialForm[input].$touched = true;
                    $scope.credentialForm[input].$dirty = true;
                });
            }
        }
    };

    $scope.error = "ok";
    $scope.saveStatus;
    $scope.saveStatusParams;

    $scope.updatePassWord = function (flowName, isValid, password, newPassword) {
        // if input validation fails, redirect focus to the first password input and NVDA read all input rules again
        $scope.setFocusToFirstInputElement();

        if (($scope.credentialForm && $scope.credentialForm.$valid) || isValid) {
            $scope.showSpinner = true;
            if (isValid && password && newPassword) {
                //then data coming from the react side
                $scope.profile.password = password;
                $scope.profile.newPassword = newPassword;
            }
            eventBus.dispatch(profileEvents.UPDATE_PASS_VALID, this);
            const updatePassWordData = {
                username: "NOT_NEEDED",
                currentpassword: $scope.profile.password,
                newpassword: $scope.profile.newPassword,
                flowName: flowName
            };

            profileFactory.updateCredentials.query(
                updatePassWordData,
                function () {
                    if (flowName === "profile") {
                        $state.go("profile");
                        $modalInstance.dismiss("cancel");
                    }
                },

                function (response) {
                    $scope.showSpinner = false;
                    if (response === "44403") {
                        $modalInstance.dismiss("cancel");
                    }

                    if (
                        response.headers("exception") !== null ||
                        response.headers("exceptionMessage") !== null
                    ) {
                        $scope.saveStatus = response.data.error.code;
                        $scope.saveStatusParams = response.data.error.errors[0];
                        $scope.setFocusToFirstInputElement("#passwordId", $scope.saveStatus);
                    }
                }
            );
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue

            // Manually set the $dirty flag for each input in the form to trigger validation
            eventBus.dispatch(profileEvents.UPDATE_PASS_INVALID, this);
            const inputs = ["password", "newPassword", "confirmPassword"];

            if ($scope.credentialForm) {
                inputs.forEach(function (input) {
                    $scope.credentialForm[input].$touched = true;
                    $scope.credentialForm[input].$dirty = true;
                });
            }
        }
    };

    $scope.setFocusToFirstInputElement = function (formField, formStatusResponse = "") {
        // eslint-disable-next-line no-undef
        Object.keys($scope.credentialForm.$error).length !== 0 &&
            angular.element(document.querySelector("div.has-error > input")).focus();
        // eslint-disable-next-line no-undef
        !!formStatusResponse && angular.element(document.querySelector(formField)).focus();
    };
    $scope.changeUsernameInput = function () {
        eventBus.dispatch(profileEvents.CHANGE_USERNAME_INPUT, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: profileEvents.CHANGE_USERNAME_INPUT
            }
        });
    };
    $scope.changeCurrentPasswordInput = function () {
        eventBus.dispatch(profileEvents.CHANGE_CURRENT_PASS_INPUT, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: profileEvents.CHANGE_CURRENT_PASS_INPUT
            }
        });
    };
    $scope.changeNewPasswordInput = function () {
        eventBus.dispatch(profileEvents.CHANGE_NEW_PASS_INPUT, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: profileEvents.CHANGE_NEW_PASS_INPUT
            }
        });
    };
    $scope.changeNewConfirmPasswordInput = function () {
        eventBus.dispatch(profileEvents.CHANGE_NEW_CONFIRM_PASS_INPUT, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: profileEvents.CHANGE_NEW_CONFIRM_PASS_INPUT
            }
        });
    };
};

ProfileCredentialsController.$inject = [
    "$modalInstance",
    "$scope",
    "$state",
    "eventBus",
    "profileFactory"
];
export default ProfileCredentialsController;
