import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import CustomEvent from "custom-event";
import _includes from "lodash/includes";
import _mapValues from "lodash/mapValues";

const deepLinkController = function (
    $cookies,
    $location,
    $scope,
    $state,
    $stateParams,
    $rootScope,
    $window,
    AccuCodeService,
    AuthenticationStatusService,
    DefaultCustomizationRetrService,
    LogoutService,
    IndIdSelectionFactory,
    PreLoginAccuRetrievalService,
    PreLoginContactInfoService,
    PrimaryPlanSelectionFactory,
    ReSetACCUService,
    TransactionAccessRetrService,
    deepLinkFactory,
    redirectService
) {
    /** * Logger. */
    const logger = ExternalLogger.getInstance("deepLinkController");
    let authStatus = {};
    if ($stateParams.featureName) $rootScope.featureName = $stateParams.featureName;
    if ($stateParams.gaId) {
        $rootScope.gaId = $stateParams.gaId;
    }

    AuthenticationStatusService.clearCache();
    AuthenticationStatusService.getAuthData().then(function (data) {
        // Local testing adding registered flag for testing PART-45271
        function propertyToBoolean(val) {
            const booleanState = ["false", "true"].indexOf(val);
            return booleanState >= 0 ? Boolean(booleanState) : val;
        }

        authStatus = _mapValues(data, propertyToBoolean);

        if (!$rootScope.deeplinkCust) {
            deepLinkFactory.getCustomization
                .query({ accu: "Default", featureName: $rootScope.featureName })
                .$promise.then(oncustomizationSuccess);
        } else {
            oncustomizationSuccess($rootScope.deeplinkCust);
        }
    });

    const oncustomizationSuccess = function (data) {
        if ($scope.accu != "Empower" && !$rootScope.deeplinkCust) {
            deepLinkFactory.getCustomization.query(
                { accu: $scope.accu, featureName: $rootScope.featureName },
                function (data) {
                    // eslint-disable-next-line no-undef
                    angular.extend($rootScope.deeplinkCust, data);
                },
                function (response) {
                    logger.error(
                        "error occured with reading accu specific deepLinkCustomization",
                        response
                    );
                }
            );
        }

        if (authStatus.authenticated) {
            //unsetting this so the header displays as an "authenticated user"
            $rootScope.showNonAuthHeader = undefined;

            const setDeepLinkCustomization = async (deepLinkDetails) =>
                hasCustomerExperienceCustomization(deepLinkDetails) &&
                (await hasCustomerExperienceAccess(deepLinkDetails.customerExperience))
                    ? deepLinkDetails.customerExperience
                    : deepLinkDetails;

            const hasCustomerExperienceCustomization = (deepLinkDetails) =>
                // eslint-disable-next-line no-prototype-builtins
                deepLinkDetails.hasOwnProperty("customerExperience");

            const hasCustomerExperienceAccess = async (customerExperienceCustomization) =>
                _includes(
                    await TransactionAccessRetrService.query({
                        txnCode: customerExperienceCustomization.menuAccessCode
                    }).$promise.then((response) => response),
                    "true"
                );

            setDeepLinkCustomization(data).then((customization) => {
                $rootScope.deeplinkCust = customization;

                const jsonPostForDest = {};
                jsonPostForDest.targetTxnCode = $rootScope.deeplinkCust.menuAccessCode;
                jsonPostForDest.newTargetTxnCode = $rootScope.deeplinkCust.newMenuAccessCode
                    ? $rootScope.deeplinkCust.newMenuAccessCode
                    : null;
                jsonPostForDest.authTxnCodes = $rootScope.deeplinkCust.authTxnCodes;
                jsonPostForDest.newAuthTxnCodes = $rootScope.deeplinkCust.newAuthTxnCodes
                    ? $rootScope.deeplinkCust.newAuthTxnCodes
                    : null;

                const context = $scope.deeplinkCust.url.split("#")[0];
                const hash = $scope.deeplinkCust.url.split("#")[1];
                jsonPostForDest.deepLinkedUrl = $scope.deepLinkData.params
                    ? `${context}?${$scope.deepLinkData.params}#${hash}`
                    : $rootScope.deeplinkCust.url;
                jsonPostForDest.newDeepLinkedUrl = $rootScope.deeplinkCust.newUrl
                    ? $rootScope.deeplinkCust.newUrl
                    : null;

                let gaId = $rootScope.gaId;
                jsonPostForDest.gaID = gaId;

                if (!$scope.evaluateLoggingOut(data)) {
                    if (!gaId) {
                        deepLinkFactory.getPrimaryPlan.query(
                            {},
                            function (primaryPlanData) {
                                gaId = primaryPlanData.gaId;

                                if (gaId) {
                                    return IndIdSelectionFactory.retrieveAllPlansWithIndid.query(
                                        {},
                                        function (data) {
                                            const hasMultiplePlans =
                                                Object.keys(data.plansMapWithIndid).length > 1;
                                            if (hasMultiplePlans) {
                                                return redirectService.redirect(
                                                    {
                                                        destinationUrl: "d",
                                                        deepUrl:
                                                            "participant/#/d/" +
                                                            $rootScope.featureName,
                                                        plansMapWithIndid: data.plansMapWithIndid,
                                                        ...jsonPostForDest
                                                    },
                                                    "ALL"
                                                );
                                            } else {
                                                jsonPostForDest.gaID = gaId;
                                                const groupClient = String(
                                                    primaryPlanData.gaId
                                                ).split("-");
                                                const groupClientId = groupClient[0];
                                                // eslint-disable-next-line no-undef
                                                globalThis.css.group = primaryPlanData.gaId;
                                                // eslint-disable-next-line no-undef
                                                globalThis.css.groupClient = groupClientId;

                                                const customEvent = new CustomEvent(
                                                    "handleLoginSetAccountDetails",
                                                    { detail: global }
                                                );
                                                window.dispatchEvent(customEvent);

                                                $scope.redirectToDeepLinkDest(jsonPostForDest);
                                            }
                                        },
                                        function (error) {
                                            logger.error(
                                                "Couldnt retrieve plans available: ",
                                                error
                                            );
                                        }
                                    );
                                } else {
                                    jsonPostForDest.gaID = gaId;
                                }

                                const groupClient = String(primaryPlanData.gaId).split("-");
                                const groupClientId = groupClient[0];
                                // eslint-disable-next-line no-undef
                                globalThis.css.group = primaryPlanData.gaId;
                                // eslint-disable-next-line no-undef
                                globalThis.css.groupClient = groupClientId;

                                const customEvent = new CustomEvent(
                                    "handleLoginSetAccountDetails",
                                    {
                                        detail: global
                                    }
                                );
                                window.dispatchEvent(customEvent);

                                $scope.redirectToDeepLinkDest(jsonPostForDest);
                            },
                            function (error) {
                                $scope.deepLinkErrorCode = $rootScope.deeplinkCust.errors.code;
                                logger.error("primaryPlan error from deeplink ", error);
                            }
                        );
                    } else {
                        $scope.evaluateCurrentSessionAndDeepLink(data, jsonPostForDest);
                    }
                }
            });
        } else if (!authStatus.registered && authStatus.authenticated === true) {
            $state.go("register.withoutPin", {
                flowName: "deepLink",
                accu: $scope.accu,
                featureName: $rootScope.featureName
            });
        } else {
            const search = $location.search();
            $rootScope.deepLinkQueryParams = Object.entries(search);

            $state.go("login", {
                flowName: "deepLink",
                accu: $scope.accu
            });
        }
    };

    $scope.evaluateLoggingOut = function () {
        const loggingOutParamIdx = location.href.indexOf("loggingOut");
        if (loggingOutParamIdx !== -1) {
            const params = location.href
                .substring(loggingOutParamIdx, location.href.length)
                .split("=");
            if (params[1] == "true") {
                $rootScope.urlRedirect = location.href.substring(0, loggingOutParamIdx - 1);
                LogoutService.logOutServices.query(function () {
                    LogoutService.logOutPartAuth.query().$promise.then(
                        {},
                        function (data) {
                            console.log("success part auth session invalidated:" + data);
                            if (!authStatus.registered && authStatus.authenticated === true) {
                                $scope.redirectToRegister();
                            } else {
                                $scope.redirectToLogin();
                            }
                        },
                        function (error) {
                            console.log("error part auth session invalidated:" + error);
                            if (!authStatus.registered && authStatus.authenticated === true) {
                                $scope.redirectToRegister();
                            } else {
                                $scope.redirectToLogin();
                            }
                        }
                    );
                });
                return true;
            }
        }
        return false;
    };

    $scope.redirectToRegister = function () {
        $scope.deleteDataLoggingOut();
        window.location.href = $rootScope.urlRedirect;
        $state.go("register.withoutPin", { flowName: "deepLink", accu: $scope.accu });
    };

    $scope.redirectToLogin = function () {
        $scope.deleteDataLoggingOut();
        window.location.href = $rootScope.urlRedirect;
        $state.go("login", { flowName: "deepLink", accu: $scope.accu });
    };

    $scope.deleteDataLoggingOut = function () {
        $cookies.remove("JSESSIONID", {
            path: "/participant-web-services"
        });
        $cookies.remove("JSESSIONID", {
            path: "/participant-authentication"
        });
        $cookies.remove("site_tok", { path: "/participant" });
        $cookies.remove("site_tok", { path: "/" });
        $cookies.remove("X-CSRF-TOKEN", { path: "/" });
        $cookies.remove("PM-INDID-TOKEN", { path: "/" });
        $cookies.remove("PM-PLAN-TOKEN", { path: "/" });
        sessionStorage.removeItem("currentMenuState");
        sessionStorage.removeItem("activeNavItems");
        sessionStorage.removeItem("activeMenuRefOnLastState");
    };

    $scope.evaluateCurrentSessionAndDeepLink = function (_, jsonPostForDest) {
        deepLinkFactory.getPrimaryPlan.query(
            {},
            function (primaryPlanData) {
                //checking if deeplink plan is not the same as the primaryplan
                if (!($rootScope.gaId === primaryPlanData.gaId)) {
                    //starting to switch context based on the plan from the url
                    PrimaryPlanSelectionFactory.retrievePlansAvailableForPrimaryPlan.query(
                        {},
                        function (data) {
                            if (data.plansMapWithIndid) {
                                for (const [_, value] of Object.entries(data.plansMapWithIndid)) {
                                    if ($rootScope.gaId === value[0].gaId) {
                                        //getting data to call the switch service
                                        const params = {
                                            indId: value[0].indId,
                                            dbName: value[0].dbname,
                                            accuCode: value[0].pptwebAccuCode,
                                            updatedefault: "Y"
                                        };
                                        PrimaryPlanSelectionFactory.chooseDefaultAndRefresh.query(
                                            params,
                                            function (data) {
                                                $window.accu = data.data.primaryPlan.accuCode;
                                                // eslint-disable-next-line no-undef
                                                globalThis.css.accu =
                                                    data.data.primaryPlan.accuCode;
                                                ReSetACCUService.query({
                                                    accu: $window.accu
                                                }).$promise.then(function () {
                                                    //updating cookies with new accu from the switched context
                                                    $cookies.remove("accu", { path: "/" });
                                                    $cookies.put("accu", $window.accu, {
                                                        path: "/"
                                                    });

                                                    $cookies.remove("accu", {
                                                        path: "/participant"
                                                    });
                                                    $cookies.put("accu", $window.accu, {
                                                        path: "/participant"
                                                    });

                                                    $cookies.remove("clientAccu", { path: "/" });
                                                    $cookies.put("clientAccu", $window.accu, {
                                                        path: "/"
                                                    });

                                                    $cookies.remove("clientAccu", {
                                                        path: "/participant"
                                                    });
                                                    $cookies.put("clientAccu", $window.accu, {
                                                        path: "/participant"
                                                    });

                                                    $cookies.remove("PM-ACCU-TOKEN", { path: "/" });

                                                    $scope.accu = AccuCodeService.getAccuCode();
                                                    if ($scope.accu) {
                                                        $rootScope.css.accu = $scope.accu;
                                                        // eslint-disable-next-line no-undef
                                                        globalThis.css.accu = $scope.accu;
                                                    } else {
                                                        $rootScope.css.accu = $window.accu;
                                                        // eslint-disable-next-line no-undef
                                                        globalThis.css.accu = $window.accu;
                                                    }

                                                    DefaultCustomizationRetrService.query().$promise.then(
                                                        function (defaultData) {
                                                            PreLoginAccuRetrievalService.query({
                                                                accu: $scope.accu
                                                            }).$promise.then(
                                                                function (preLoginData) {
                                                                    // eslint-disable-next-line no-undef
                                                                    $scope.accuCustomization =
                                                                        angular.extend(
                                                                            {},
                                                                            defaultData,
                                                                            preLoginData
                                                                        );
                                                                    $rootScope.accuSpecificPrimDb =
                                                                        $scope.accuCustomization.primaryDatabase.preLoginPrimaryDB;

                                                                    PreLoginContactInfoService.query(
                                                                        {
                                                                            accu: $scope.accu,
                                                                            accuSpecificPrimDb:
                                                                                $rootScope.accuSpecificPrimDb,
                                                                            portalType: "TOLLFREE"
                                                                        }
                                                                    ).$promise.then(
                                                                        function (contactData) {
                                                                            $rootScope.siteContactInfo =
                                                                                contactData;
                                                                            $rootScope.$broadcast(
                                                                                "siteContactInfoUpdated",
                                                                                $rootScope.siteContactInfo
                                                                            );
                                                                            $rootScope.$broadcast(
                                                                                "loginAuthStatusVerified",
                                                                                $scope.authenticationStatus
                                                                            );
                                                                            $rootScope.$broadcast(
                                                                                "customizationUpdated",
                                                                                $scope.accuCustomization
                                                                            );
                                                                            jsonPostForDest.targetTxnCode =
                                                                                $rootScope.deeplinkCust.menuAccessCode;
                                                                            jsonPostForDest.newTargetTxnCode =
                                                                                $rootScope
                                                                                    .deeplinkCust
                                                                                    .newMenuAccessCode
                                                                                    ? $rootScope
                                                                                          .deeplinkCust
                                                                                          .newMenuAccessCode
                                                                                    : null;
                                                                            jsonPostForDest.authTxnCodes =
                                                                                $rootScope.deeplinkCust.authTxnCodes;
                                                                            jsonPostForDest.newAuthTxnCodes =
                                                                                $rootScope
                                                                                    .deeplinkCust
                                                                                    .newAuthTxnCodes
                                                                                    ? $rootScope
                                                                                          .deeplinkCust
                                                                                          .newAuthTxnCodes
                                                                                    : null;
                                                                            jsonPostForDest.deepLinkedUrl =
                                                                                $rootScope.deeplinkCust.url;
                                                                            jsonPostForDest.newDeepLinkedUrl =
                                                                                $rootScope
                                                                                    .deeplinkCust
                                                                                    .newUrl
                                                                                    ? $rootScope
                                                                                          .deeplinkCust
                                                                                          .newUrl
                                                                                    : null;

                                                                            //doing redirection to the deeplink with the switched context
                                                                            $scope.redirectToDeepLinkDest(
                                                                                jsonPostForDest
                                                                            );
                                                                        }
                                                                    );
                                                                }
                                                            );
                                                        }
                                                    );
                                                });
                                            },
                                            function (error) {
                                                $scope.deepLinkErrorCode =
                                                    $rootScope.deeplinkCust.errors.code;
                                                logger.error(
                                                    "Error when switching context for deeplink",
                                                    error
                                                );
                                            }
                                        );
                                    }
                                }
                            }
                        }
                    );
                } else {
                    jsonPostForDest.targetTxnCode = $rootScope.deeplinkCust.menuAccessCode;
                    jsonPostForDest.newTargetTxnCode = $rootScope.deeplinkCust.newMenuAccessCode
                        ? $rootScope.deeplinkCust.newMenuAccessCode
                        : null;
                    jsonPostForDest.authTxnCodes = $rootScope.deeplinkCust.authTxnCodes;
                    jsonPostForDest.newAuthTxnCodes = $rootScope.deeplinkCust.newAuthTxnCodes
                        ? $rootScope.deeplinkCust.newAuthTxnCodes
                        : null;
                    jsonPostForDest.deepLinkedUrl = $rootScope.deeplinkCust.url;
                    jsonPostForDest.newDeepLinkedUrl = $rootScope.deeplinkCust.newUrl
                        ? $rootScope.deeplinkCust.newUrl
                        : null;
                    $scope.redirectToDeepLinkDest(jsonPostForDest);
                }
            },
            function (error) {
                $scope.deepLinkErrorCode = $rootScope.deeplinkCust.errors.code;
                logger.error("primaryPlan error from deeplink ", error);
            }
        );
    };

    $scope.goToUrl = function (targetUrl) {
        $window.location.href = targetUrl;
    };

    $scope.redirectToDeepLinkDest = function (jsonPostForDest) {
        const handleError = () => {
            redirectService.redirect({
                destinationUrl: "participant/accounts/#/",
                flowName: "deepLink"
            });
        };

        deepLinkFactory.getDestination.query(
            jsonPostForDest,
            function (data) {
                if (data.deepLinkEligible) {
                    const deepLinkURL = location.search;
                    let urlToBeRedircted = data.destinationUrl
                        ? data.destinationUrl
                        : $scope.deeplinkCust.url;

                    urlToBeRedircted = urlToBeRedircted.replace(":queryParams", deepLinkURL);
                    urlToBeRedircted = urlToBeRedircted.replace(":indId", data.indID);
                    urlToBeRedircted = urlToBeRedircted.replace(":gaId", jsonPostForDest.gaID);
                    urlToBeRedircted = urlToBeRedircted.replace(":groupId", jsonPostForDest.gaID);
                    $scope.goToUrl("../../" + urlToBeRedircted);
                } else if (!data.deepLinkEligible && data.destinationUrl) {
                    /**
                     * The below if condition is that if the user is coming up with deeplink with seconday plan which is in enrollment then we have to supercede the deeplink and
                     * redirect the user to the enrollment flow.
                     */
                    $scope.goToUrl("../../" + data.destinationUrl);
                } else {
                    handleError();
                }
            },
            function () {
                handleError();
            }
        );
    };
};

deepLinkController.$inject = [
    "$cookies",
    "$location",
    "$scope",
    "$state",
    "$stateParams",
    "$rootScope",
    "$window",
    "AccuCodeService",
    "AuthenticationStatusService",
    "DefaultCustomizationRetrService",
    "LogoutService",
    "IndIdSelectionFactory",
    "PreLoginAccuRetrievalService",
    "PreLoginContactInfoService",
    "PrimaryPlanSelectionFactory",
    "ReSetACCUService",
    "TransactionAccessRetrService",
    "deepLinkFactory",
    "redirectService"
];

export default deepLinkController;
