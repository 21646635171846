import "./utils/setJquery";
import "./utils/setWindowVariables";
import "./utils/setGlobalThisVariables";
import "./utils/setLogLevel";
import eventBus from "./utils/setEventBus";
import "core-ui/client/src/app/core/util/googleAnalytics/bootstrapGoogleAnalytics";
import { setForceSentryDebug } from "core-ui/client/react/core/utils/setSentryDebug";
import "./Application";

import * as Sentry from "@sentry/browser";
import {
    userLanguagePreference,
    pageLanguageSetting
} from "core-ui/client/react/core/utils/browserLocaleUtils";
import { getEnvironmentDisplayName } from "core-ui/client/react/core/utils/getEnvironmentDisplayName";

import formatTimingPrefix from "./utils/sentryMetricsLogging/formatTimingPrefix";

const url = window.location.origin.toLocaleLowerCase();
const isLocalhost = url.indexOf("127.0.0.1") > -1 || url.indexOf("localhost") > -1;

eventBus.amplitudeInit({});

if (!isLocalhost) {
    let sampleRate = window?.sentryConfig?.sampleRate;
    let tracesSampleRate = window?.sentryConfig?.tracesSampleRate;
    const sentryEnv = getEnvironmentDisplayName(window.location.host);

    if (sentryEnv !== "Production" && window.sentryConfig.lowerEnvironments) {
        sampleRate = window?.sentryConfig?.lowerEnvironments?.sampleRate;
        tracesSampleRate = window?.sentryConfig?.lowerEnvironments?.tracesSampleRate;
    }
    // override sample rate if forceSentryDebug is set to true
    setForceSentryDebug();
    const forceSentryDebug = localStorage.getItem("forceSentryDebug");
    if (forceSentryDebug === "true") {
        sampleRate = 1;
        tracesSampleRate = 1;
    }

    Sentry.init({
        dsn: "https://fa5197db62314320a0e82ed740410868@o249255.ingest.us.sentry.io/4505086927306752",
        integrations: [
            Sentry.browserTracingIntegration({
                enableInp: true
            })
        ],
        release: "login-ui@" + __applicationVersion__,
        environment: sentryEnv,
        sampleRate: sampleRate,
        tracesSampleRate: tracesSampleRate
    });

    const timingPrefix = formatTimingPrefix();
    const LOGIN_LAST_HASH = "loginLashHash";
    const eventName = `${timingPrefix}${LOGIN_LAST_HASH}`;

    // set initial hash
    sessionStorage.setItem(eventName, window.location.hash);
    Sentry.setTag("location_hash", window.location.hash);

    window.addEventListener("hashchange", () => {
        sessionStorage.setItem(eventName, window.location.hash);
        Sentry.setTag("location_hash", window.location.hash);
    });

    Sentry.setTag("user_locale", userLanguagePreference());
    Sentry.setTag("page_locale", pageLanguageSetting());
}

/*const loadReactApp = () => {
    const container = document.getElementById("app-container");
    console.log('index.jsx - top load react app')
    const root = createRoot(container);
    root.render(<ApplicationContainer />);
}; */

//loadReactApp();
