/* eslint-disable react/prop-types */
import React from "react";

import LogService from "core-ui/client/src/app/core/logger/LogService";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import Form from "react-validation/build/form";

import { verifyOneTimePasswordCode } from "../../../services/idProof/otp/OneTimePasswordService";
import TranslationService from "../../../services/translationService";

export default class VerificationCodeEntryComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            translations: null,
            verificationCode: null,
            verificationCodeError: null
        };
    }

    isEmpty = (str) => {
        if (str === null || str === undefined) {
            return true;
        }
        if (str.replace(/\s/g, "") === "") {
            return true;
        }

        return false;
    };

    /**
     * returns true if the supplied string is not a valid phone number.
     */
    isInvalidPhoneNumberPattern = (str) => {
        if (str === null || str === undefined) {
            return true;
        }
        const phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        const rc = !phoneNumberPattern.test(str);
        return rc;
    };

    validateVerificationCode = (value) => {
        this.setState({ verificationCode: value });
        if (!value || this.isEmpty(value)) {
            this.setState({
                verificationCodeError: this.state.translations.verificationcodeRequired
            });
            return;
        }

        this.setState({ verificationCodeError: null });
    };

    handleVerificationCodeChange = (event) => {
        const value = event.target.value;
        this.validateVerificationCode(value);
    };

    renderVerificationCodeErrorMessage = () => {
        if (this.state.verificationCodeError) {
            return (
                <div className="form-group has-error" id="screenReader" tabIndex="0">
                    <span id="helpBlock" className="help-block">
                        {this.state.verificationCodeError}
                    </span>
                </div>
            );
        }
    };

    /**
     *  handle error messaging.
     *  if 'data' is provided, it could have a custom error code.
     *  if 'data' is not provided, then an http exception occurred.
     */
    handleError = (data, error) => {
        LogService.getLogger().error(
            "handleError. data=[" +
                (data ? JSON.stringify(data) : "null") +
                "] error: " +
                (error ? JSON.stringify(error) : "null")
        );

        let contentLabel =
            this.state.translations?.oneTimePassword?.identityVerification?.errors.UNKNOWN;

        if (data && data.errorCode) {
            const variableLabel =
                this.state.translations?.oneTimePassword?.identityVerification?.errors[
                    data.errorCode
                ];
            if (variableLabel) {
                contentLabel = variableLabel;
            }

            if (data.state === "MAIL_PIN") {
                if (this.props.options.onOtpError) {
                    if (data.flowName === "otsAccountSetup") {
                        this.props.options.onOtpError();
                    } else {
                        this.props.options.onOtpError(data.state);
                    }
                }
            } else {
                this.setState({ isLoading: false });
                this.setState({ verificationCodeError: contentLabel });
            }

            //StateChangeService.goToState('idProofError',{ errorCode:  data.errorCode });
        }
    };

    /**
     *  Handle a successful response.
     */
    handleSuccess = (data) => {
        LogService.getLogger().error(
            "handleSuccess. data=[" + (data ? JSON.stringify(data) : "null") + "]"
        );
        this.setState({ isLoading: false });

        if (this.props.options.onConfirm) {
            this.props.options.onConfirm(this.props.options.phoneNumber, data);
        }
    };

    handleNoCodeReceived = (e) => {
        e.preventDefault();
        if (this.props.isOtsUser) {
            this.props.options.onOtpError();
        } else {
            this.props.noCodeFunction();
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        this.validateVerificationCode(this.state.verificationCode);
        if (this.isFormValid()) {
            verifyOneTimePasswordCode(
                { value: this.state.verificationCode },
                this.handleSuccess,
                this.handleError
            );
        } else {
            this.setState({ isLoading: false });
        }
    };

    onCancelClick = (e) => {
        e.preventDefault();
        this.props.options.onCancel();
    };

    isFormValid = () => {
        return !!this.state.verificationCode && !this.state.verificaiontCodeError;
    };

    getFieldClass = (val) => {
        let rc = "form-group";
        if (this.state[val + "Error"]) {
            rc += " has-error";
        }
        return rc;
    };

    /**
     * render the submit button.
     * moved to a separate function to account for all the loading/disabled/enabled logic.
     */
    renderSubmitButton = () => {
        //btn-lg
        let className = "btn btn-primary btn-lg btn-block margin-top-default ";
        const isLoading = this.state.isLoading;
        const isFormValid = this.isFormValid();
        let disabled = false;
        let label = "Sign In";

        if (isFormValid) {
            if (isLoading) {
                disabled = true;
                label =
                    this.state.translations?.oneTimePassword?.identityVerification
                        ?.agreeButtonProcessing;
                className += " pw-loader disabled-loading";
            }
        } else {
            disabled = true;
        }

        return (
            <button
                className={className}
                id="signin"
                disabled={disabled}
                onClick={this.handleSubmit}
            >
                {label}
            </button>
        );
    };

    render() {
        if (!this.state.translations) {
            return null;
        }
        return (
            <div
                className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration"
                data-testid="verification-code-entry-component"
            >
                <header className="contained-form-header">
                    <h1>
                        {this.state.translations?.oneTimePassword?.verificationCodeEntry?.header}
                    </h1>
                    <p>
                        {
                            this.state.translations?.oneTimePassword?.verificationCodeEntry
                                ?.codeSentMessageLogin
                        }
                    </p>
                </header>

                <div className="inner-container with-padding with-shadow">
                    <Form
                        ref={(c) => {
                            this.form = c;
                        }}
                        onSubmit={this.handleSubmit}
                    >
                        <div className={`form-group ${this.getFieldClass("verificationCode")}`}>
                            <label htmlFor="verificationCode" className="control-label">
                                {this.state.translations?.pleaseEnterCode}
                            </label>
                            <MaskedInput
                                mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                className="form-control col-12"
                                guide={false}
                                id="verificationCode"
                                onBlur={this.handleVerificationCodeChange}
                                onChange={this.handleVerificationCodeChange}
                                autoComplete="off"
                                aria-label="verificationCode"
                            ></MaskedInput>
                            {this.renderVerificationCodeErrorMessage()}
                        </div>
                        <a href="#" className="no-code" onClick={this.handleNoCodeReceived}>
                            {this.state.translations?.didNotRecieveCode}
                        </a>

                        <div className="form-group clearfix">{this.renderSubmitButton()}</div>
                    </Form>
                </div>
            </div>
        );
    }

    /**
     * get the translations.
     */
    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    /*#################################################################
     # LifeCycle Hooks
     #################################################################*/

    componentDidMount() {
        this.getTranslations();
    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate() {
        if (this.state.translations) {
            this.renderVerificationCodeErrorMessage();
        }
    }
}

VerificationCodeEntryComponent.propTypes = {
    options: PropTypes.object.isRequired,
    noCodeFunction: PropTypes.func
};
