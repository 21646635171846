import ReferenceData from "../../constants/ReferenceData";
import { executeGet } from "../../utils/appUtils";
import { DeliveryOptionsResponseData } from "./getDeliveryOptions";
import StringUtil from "core-ui/client/src/app/StringUtil";

type DeliveryOptionsSuccessResponse = DeliveryOptionsResponseData | undefined;

interface DeliveryOptionsParams {
    dbName: string;
    indId: string;
}

const getDeliveryOptionsByIndId = (params: DeliveryOptionsParams) => {
    const deliveryOptionsByIndIdSuccess = (results): DeliveryOptionsSuccessResponse => {
        if (results?.data) {
            return results.data;
        }
    };

    return executeGet(
        StringUtil.supplant(ReferenceData.GET_DELIVERY_OPTIONS_BY_IND_ID, params),
        "deliveryOptionsByIndIdFault"
    )
        .then(deliveryOptionsByIndIdSuccess)
        .catch((error: unknown) => {
            throw error;
        });
};

export default getDeliveryOptionsByIndId;
