export const ACCOUNT_RECOVERY = "account-recovery";
export const ACCOUNT_RECOVERY_MULTIPLE_USERS = "account-recovery-multipleUsrs";
export const ACCOUNT_RECOVERY_CONFIRMATION = "account-recovery-confirm";
export const HELLO_WORLD = "helloworld";
export const LOGIN = "login";
export const LOGIN_WITH_ACCU = "login?accu=";
export const LOGIN_HELP_WITH_ACCU = "loginHelp/*";
export const MFA_ACTIVATION_CODE_DELIVERY_OPTIONS = "activationCodeDeliveryOptions";
export const MFA_VERIFY_CODE = "verifyCode";
export const NPDI = "npdi/*";
export const NPDI_BASE = "/npdi";
export const NPDI_ACCOUNT_EXISTS = "accountExists";
export const NPDI_LINK_ACCOUNTS = "linkAccounts";
export const ACCOUNT_SETUP = "accountSetup";
export const NPDI_ERROR = "error";
export const REGISTER = "register/*";
export const ROUTE_SIGNIN = "signin";
export const SIGNIN_WITH_ACCU = "signin?accu=";
export const ACTIVATION_CODE_DELIVERY_OPTIONS = "/activationCodeDeliveryOptions";
export const VERIFY_CODE = "/verifyCode";
export const CONTACT_UPDATE = "contact-update";
export const REGISTER_INFO = "register-info";
export const ONE_ID_DELIVERY_OPTIONS = "/oneIdMfaActivationCode";
export const ONE_ID_VERIFY_CODE = "/oneIdMfaEnterCode";
