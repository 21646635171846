import ReferenceData from "../../constants/ReferenceData";
import { executePost } from "../../utils/appUtils";

interface VerifyCodePayload {
    rememberDevice: boolean;
    verificationCode: string;
}

interface VerifyCodeResponseData {
    destinationUrl: string;
    state: string;
}

type VerifyCodeSuccessResponse = VerifyCodeResponseData | undefined;

const verifyCode = (payload: VerifyCodePayload) => {
    const verifyCodeSuccess = (results): VerifyCodeSuccessResponse => {
        if (results?.data) {
            return results.data;
        }
    };

    return executePost(ReferenceData.VERIFY_CODE, "verifyCodeFault", payload)
        .then(verifyCodeSuccess)
        .catch((error) => {
            throw error;
        });
};

export default verifyCode;
