import dateUtil from "core-ui/client/src/app/DateUtil";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import MaskUtil from "core-ui/client/src/app/MaskUtil";
import _findIndex from "lodash/findIndex";
import _isNumber from "lodash/isNumber";
import _isUndefined from "lodash/isUndefined";
import _keys from "lodash/keys";

import NpdiAccountSetupEvents from "./events/NpdiAccountSetupEvents";

const npdiRegistrationController = function (
    $q,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $window,
    PersonalContactInfoCustomValidator,
    PersonalContactInfoUpdateUtil,
    npdiFactory,
    redirectService,
    eventBus
) {
    const logger = ExternalLogger.getInstance("npdiRegistrationController");

    let previousSSN;
    let newSSN;
    let previousDateOfBirth;
    const deferred = $q.defer();

    $scope.thisScope = $scope;

    $scope.submitted = false;
    $scope.showSpinner = false;
    $scope.personalInfo = {
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        ssn: "",
        maritalStatus: ""
    };

    $scope.employmentInfo = {
        currentAnnualIncome: "",
        dateOfHire: "",
        divisionCode: ""
    };

    $scope.mailingAddress = {
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipcode: ""
    };

    $scope.stateOptions = {
        code: "",
        description: ""
    };
    $scope.selectedStateValue = "";

    $scope.zipcode = "";

    $scope.countryOptions = {
        code: "",
        description: ""
    };
    $scope.selectedCountryValue = "";
    $scope.isLawOfficer = "";

    $scope.maritalStatusOptions = [];

    $scope.genderListOptions = [];

    $scope.selectedGenderValue = "";

    $scope.divisionCodesOptions = {
        name: "",
        divisionCode: ""
    };
    $scope.selectedDivCodesValue = "";

    $scope.showDivisionCode = false;

    $scope.showCTPEC = false;
    $scope.showLawOfficer = "";

    $scope.selectedMaritalStatusValue = "";

    $scope.userInfo = {
        username: "",
        password: "",
        confirmPassword: ""
    };

    $scope.ctPec = {
        inputId: "",
        employeeId: "",
        employeeRCD: ""
    };

    $scope.stateAgency = {
        value: ""
    };

    $scope.showStateAgency = false;

    $scope.dispatchStateAgencyEvent = (val) => {
        eventBus.dispatch(NpdiAccountSetupEvents[`STATE_AGENCY_${val}`]);
    };

    $scope.handleBlurInput = function (event) {
        event.currentTarget.type = "password";
    };

    $scope.handleFocusInput = function (event) {
        event.currentTarget.type = "text";
    };

    $scope.cleaveoptions = {
        ssn: {
            blocks: [3, 2, 4],
            uppercase: true,
            delimiters: ["-"]
        }
    };

    $scope.validationStatus = "";
    $scope.validationStatusParams = "";
    $scope.errors = [];

    /**
     * focus manager
     * indexed by fieldName
     * @type {{}}
     */
    $scope.focusMgr = {};

    $scope.isDateOfBirthRequired = function () {
        return true;
    };

    $scope.changeLawOfficerSelection = function (evt) {
        const value = evt.target.value;
        let clickEvent = "Law_enforcement_officer.no.click";
        if (value === "Y") {
            clickEvent = "Law_enforcement_officer.yes.click";
        }
        $window.dataLayer.push({
            event: clickEvent
        });
        npdiFactory.isLawOfficer = evt.target.value;
        $scope.isLawOfficer = evt.target.value;
    };

    $scope.getMinimumDateOfBirth = function () {
        return dateUtil.getMinimumDateOfBirth();
    };

    $scope.fieldOnBlur = function (fieldName) {
        if (_isUndefined($scope.npdiForm)) {
            return "";
        }
        const field = $scope.npdiForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field.$touched && field.$invalid && !fieldFocused) {
            status = "has-error";
        }
        /*            if (fieldName === 'divisionCodes'){
                        console.log('fieldOnBlur - status',fieldName, status);
                    }*/
        return status;
    };

    $scope.getFormGroupClass = function (fieldName) {
        if (_isUndefined($scope.npdiForm)) {
            return "";
        }

        const field = $scope.npdiForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        const fieldTouched = $scope.isTouched(fieldName) || false;
        let status = "";
        if (
            (field && field.$invalid && field.$dirty && !fieldFocused) ||
            (field && fieldName === "currentAnnualIncome" && fieldTouched && field.$invalid)
        ) {
            status = "has-error";
        }

        if (field && field.$valid && field.$dirty) {
            status = "has-success";
        }
        return status;
    };

    $scope.isTouched = function (fieldName) {
        return $scope.npdiForm[fieldName].$dirty;
    };

    $scope.isDirty = function (fieldName) {
        if (_isUndefined($scope.npdiForm)) {
            return false;
        }
        const dirty = $scope.npdiForm[fieldName] !== undefined && $scope.npdiForm[fieldName].$dirty;

        /*            if (fieldName === 'divisionCodes'){
                        console.log('dirty',fieldName, dirty);
                    }*/
        return dirty;
    };

    $scope.displayFieldMessages = function (fieldName, suppressFocused) {
        if (_isUndefined(suppressFocused)) {
            suppressFocused = false;
        }

        if (_isUndefined($scope.npdiForm)) {
            return "";
        }
        const field = $scope.npdiForm[fieldName];
        if (_isUndefined($scope.npdiForm) || !field) {
            return true;
        }
        const conditions =
            (field.$invalid && field.$dirty && !suppressFocused) ||
            (field.$touched && !suppressFocused) ||
            (!field.$pristine && !suppressFocused);

        /*            if (fieldName === 'divisionCodes'){
                        console.log('conditions',fieldName, conditions);
                    }*/
        return conditions;
    };

    $scope.getFieldError = function (fieldName) {
        if (
            _isUndefined($scope.npdiForm) ||
            !fieldName ||
            _isUndefined($scope.npdiForm[fieldName])
        ) {
            return "";
        }
        /*            if (fieldName === 'divisionCodes'){
                        console.log('$scope.npdiForm[fieldName].$error',fieldName, $scope.npdiForm[fieldName].$error);
                    }*/
        return $scope.npdiForm[fieldName].$error;
    };

    /**
     * determine the validation error (style) class.
     * different class if one error vs multiples.
     */
    $scope.getValidationErrorClass = function (fieldName) {
        let styleName = "rule-validations";

        if (
            _isUndefined($scope.npdiForm) ||
            !fieldName ||
            _isUndefined($scope.npdiForm[fieldName])
        ) {
            return styleName;
        }
        const errs = $scope.npdiForm[fieldName].$error;
        const errorKeys = _keys(errs);

        /*
        if (fieldName === 'divisionCodes'){
            console.log('errorKeys',fieldName, errorKeys);
            _pull(errorKeys,'mask');
            console.log('errorKeys after',fieldName, errorKeys);
        }
        */

        //console.log('NpdiRegistrationController errorKeys final',fieldName, errorKeys);
        const errorCnt = errorKeys.length;

        //if >1 errors, the style class should be bulleted.
        if (errorCnt > 1) {
            styleName = "form-validation-rule";
        }

        /*            if (fieldName === 'divisionCodes'){
                        console.log('styleName',fieldName, styleName);
                    }*/
        return styleName;
    };

    $scope.removeInputFocus = function (event) {
        $timeout(function () {
            $scope.focusMgr[event.target.name] = false;
        });
    };

    $scope.setInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = true;
        $scope.npdiForm[event.target.name].$setTouched(true);
        $scope.npdiForm[event.target.name].$setDirty(true);
        $scope.isOpenPopup = true;
    };

    $scope.getMinimumDateOfBirth = function () {
        return dateUtil.getMinimumDateOfBirth();
    };

    $scope.clearZipcode = function () {
        $scope.zipcode = "";
    };

    $scope.zipcodeUpdated = function () {
        // eslint-disable-next-line no-undef
        $scope.zipcode = document.getElementById("zipcodeInput").value;
    };

    $scope.retrieveDropdownValuesOnNpdiPageLoad = function () {
        npdiFactory.retrieveDropdownValuesOnNpdiPageLoad.query().$promise.then(
            function (data) {
                if (data !== null) {
                    if (data.states !== null) {
                        $scope.stateOptions = normalizeRecords(data.states);
                    }
                    if (data.countries !== null) {
                        $scope.countryOptions = normalizeRecords(data.countries);
                    }
                    if (data.maritalStatusList !== null) {
                        $scope.maritalStatusOptions = normalizeRecords(data.maritalStatusList);
                    }

                    if (data.genderList !== null) {
                        console.log("data.genderList=" + data.genderList);
                        $scope.genderListOptions = data.genderList;
                        console.log(
                            "genderListOptions =" +
                                $scope.genderListOptions +
                                "," +
                                $scope.genderListOptions[0] +
                                "," +
                                $scope.genderListOptions[1]
                        );
                    }

                    console.log("data.divisionCodes=" + data.divisionCodes);

                    if (data.divisionCodes !== null) {
                        console.log("data.divisionCodes is not null");
                        $scope.divisionCodesOptions = normalizeDivCodes(data.divisionCodes);
                        console.log("$scope.divisionCodesOptions=" + $scope.divisionCodesOptions);
                        console.log(
                            "$scope.divisionCodesOptions.length=" +
                                $scope.divisionCodesOptions.length
                        );
                        if ($scope.divisionCodesOptions.length === 1) {
                            $scope.selectedDivCodesValue = $scope.divisionCodesOptions;
                        } else {
                            $scope.showDivisionCode = true;
                        }
                        console.log("$scope.showDivisionCode =" + $scope.showDivisionCode);
                    }

                    $scope.showLawOfficer = data.lawEnforcementOfficer;

                    if (data.customCTPEC === "HIDE_STATE_AGENCY_QN") {
                        $scope.showCTPEC = true;
                        $scope.showDivisionCode = false;
                    }

                    if (data.customCTPEC === "SHOW_STATE_AGENCY_QN") {
                        $scope.showStateAgency = true;
                        $scope.showDivisionCode = false;
                    }

                    $scope.selectedCountryValue = getCodeValue($scope.countryOptions, "US");
                }
            },
            function (error) {
                console.error("retrieveDropdownValuesOnNpdiPageLoad - ERROR - ", error);
                if (error?.data?.status == "FAIL") {
                    eventBus.dispatchAmplitude({
                        event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                        event_action: "npdi - retrieveDropdownValuesOnNpdiPageLoad",
                        event_properties: {
                            login_error_code: error?.data?.error?.code,
                            error_message: error?.data?.error?.errors[0]
                        }
                    });
                }
            }
        );
    };

    $scope.loginLinkClick = function () {
        $state.go("login", {});
    };

    $scope.populateSelectedCntryPhoneCode = function (fieldName) {
        //eslint-disable-next-line no-undef
        const temp = document.getElementById(fieldName).value;
        if (fieldName === "ContactCountryNameI") {
            $scope.selectedCntryPhCodeI = "+" + temp.substring(2);
        } else if (fieldName === "ContactCountryNameD") {
            $scope.selectedCntryPhCodeD = "+" + temp.substring(2);
        }
    };

    $scope.clearValues = function () {
        $scope.selectedDivCodesValue = "";
    };

    $scope.retrieveContactDetailsDropdown = function () {
        npdiFactory.retrieveContactDetailsDropdown.query(
            {},
            function (data) {
                console.log("   Contact info retrived" + data);
                if (data !== null) {
                    $scope.contactDetails = data;
                    $scope.intlNumber = data.intlNumber;
                    $scope.personalEmailAddress = data.personalEmailAddress;
                    $scope.mobilePhoneNumber = data.domesticMobile;
                    if (data.intlNumber != null) {
                        $scope.intlNumberIdI = $scope.intlNumber;
                        $scope.intlNumberIdD = $scope.intlNumber;
                    }

                    if (data.domesticMobile !== null) {
                        $scope.mobilePhoneNumber = data.domesticMobile;
                        $scope.phoneNumberIdD = data.domesticMobile;
                        $scope.phoneNumberIdI = data.domesticMobile;
                        $scope.selectedCntryPhCodeD = "+" + data.domesticCntryCode.substring(2);
                    }

                    if (data.intCntryCode != null && data.intCntryCode != "")
                        $scope.selectedCntryPhCodeI = "+" + data.intCntryCode.substring(2);

                    /* $scope.contactInfo.phoneNumber = data.data.phoneNumber;
                     $scope.contactInfo.phoneNumberDisplay = "(" + data.data.phoneNumber.substring(0, 3) + ") " +
                         data.data.phoneNumber.substring(3, 6) +
                         "-" + data.data.phoneNumber.substring(6);*/
                }
            },
            function (error) {
                if (error.data.status == "FAIL") {
                    $scope.saveStatus = error.data.error.code;
                    $scope.saveStatusParams = error.data.error.errors[0];

                    eventBus.dispatchAmplitude({
                        event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                        event_action: "npdi - retrieveContactDetailsDropdown",
                        event_properties: {
                            login_error_code: error?.data?.error?.code,
                            error_message: error?.data?.error?.errors[0]
                        }
                    });
                }
            }
        );
    };

    $scope.handleMaskSSN = (event) => {
        const method = "handleMaskSSN()";
        const SSN = $scope.npdiForm.ssn.$viewValue;
        const SSNPattern = /^\d{3}-\d{2}-\d{4}/;

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);

        if (SSN === "" && !_isUndefined(previousSSN)) {
            if (MaskUtil.isMasked(previousSSN)) {
                $scope.personalInfo.ssn = previousSSN;
                newSSN = null;
            } else {
                $scope.personalInfo.ssn = MaskUtil.maskSSN(previousSSN);
            }
        }

        if (SSNPattern.test(SSN)) {
            newSSN = SSN.replace(/-/g, "");
            $scope.personalInfo.ssn = MaskUtil.maskSSN(SSN);
        } else {
            logger.debug("{0} - ssn is not valid pattern", [method]);
        }

        logger.debug("{0} - exiting handleMaskSSN", [method]);
    };

    $scope.toggleSSNfromMaskedClick = (event) => {
        const method = "toggleSSNfromMaskedClick()";

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);

        if (!event) {
            logger.debug("{0} - no event.", [method]);
            return;
        }

        if (event.type === "touchend") {
            logger.debug("{0} - ignoring superfluous 'touchend'.", [method]);
            return;
        }

        $scope.focusInput(event);

        if ($scope.isSSNMasked($scope.personalInfo.ssn)) {
            previousSSN = newSSN ? newSSN : $scope.personalInfo.ssn;
            $scope.personalInfo.ssn = "";
        }
    };

    $scope.focusInput = (event) => {
        const method = "focusInput()";

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);

        $timeout(() => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }, 500);
    };

    $scope.isSSNMasked = (SSN) => {
        return MaskUtil.isMasked(SSN);
    };

    $scope.toggleSSNfromMaskedKeyDown = (event) => {
        if (!$scope.isNavigationKey(event.keyCode)) {
            if ($scope.isSSNMasked($scope.personalInfo.ssn)) {
                const charCode = String.fromCharCode(event.keyCode);

                previousSSN = newSSN ? newSSN : $scope.personalInfo.ssn;
                $scope.personalInfo.ssn = _isNumber(charCode) ? charCode : "";
            }
        }
    };

    $scope.isNavigationKey = (keyCode) => {
        return keyCode && (keyCode === 9 || keyCode === 16 || (keyCode >= 35 && keyCode <= 40));
    };

    $scope.touchDateOfBirth = (event) => {
        const method = "touchDateOfBirth()";

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);
    };

    $scope.toggleDateOfBirthFromClick = (event) => {
        const method = "toggleDateOfBirthFromClick()";
        const dateOfBirth = $scope.npdiForm.dateOfBirth.$viewValue;

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);

        if (!event) {
            logger.debug("{0} - no event.", [method]);
            return;
        }
        if (event.type === "touchend") {
            logger.debug("{0} - ignoring superfluous 'touchend'.", [method]);
            return;
        }

        $scope.focusInput(event);

        if (
            MaskUtil.isMasked($scope.personalInfo.dateOfBirth) ||
            $scope.npdiForm.dateOfBirth.$valid
        ) {
            previousDateOfBirth = dateOfBirth;
            $scope.personalInfo.dateOfBirth = "";
        }
    };

    $scope.handleMaskDateOfBirth = (event) => {
        const method = "handleMaskDateOfBirth()";
        const dateOfBirth = $scope.npdiForm.dateOfBirth.$viewValue;

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);

        if (dateOfBirth === "" && !_isUndefined(previousDateOfBirth)) {
            $scope.personalInfo.dateOfBirth = previousDateOfBirth;
            previousDateOfBirth = "";
        }
    };

    $scope.toggleDateOfBirthfromKeyDown = (event) => {
        const dateOfBirth = $scope.npdiForm.dateOfBirth.$viewValue;

        if (!$scope.isNavigationKey(event.keyCode)) {
            const charCode = String.fromCharCode(event.keyCode);

            if (
                MaskUtil.isMasked($scope.personalInfo.dateOfBirth) ||
                $scope.npdiForm.dateOfBirth.$valid
            ) {
                previousDateOfBirth = dateOfBirth;
                $scope.personalInfo.dateOfBirth = _isNumber(charCode) ? charCode : "";
            }
        }
    };

    const normalizeRecords = function (records) {
        const normalizedRecords = [];

        angular.forEach(records, function (record) {
            if (record.code !== null && record.description !== null) {
                normalizedRecords.push({
                    code: record.code,
                    description: record.description.toProperCase()
                });
            }
        });
        return normalizedRecords;
    };

    const normalizeDivCodes = function (records) {
        const normalizeDivCodes = [];
        angular.forEach(records, function (record) {
            if (record.name !== null && record.divisionCode !== null) {
                normalizeDivCodes.push({
                    name: record.name,
                    divisionCode: record.divisionCode
                });
            }
        });
        return normalizeDivCodes;
    };

    String.prototype.toProperCase = function () {
        return this.toLowerCase().replace(/(^[a-z]| [a-z]|-[a-z])/g, function ($1) {
            return $1.toUpperCase();
        });
    };

    const getCodeValue = function (source, code) {
        if (typeof source === "undefined" || typeof code === "undefined") {
            return null;
        }

        const index = _findIndex(source, { code: code });

        return index >= 0 ? source[index] : null;
    };

    $scope.setUpAccount = function () {
        // Everything is good, we can continue with registration
        $rootScope.flowName = "npdiAccountSetupFlow";

        const contactPostData = PersonalContactInfoUpdateUtil.getUpdateContactInfoJson(
            $rootScope.flowName,
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode
        );
        const validaityOfForm = PersonalContactInfoCustomValidator.validatePersonalInfo(
            $scope.contactDetails.personalEmailAddress,
            $scope.contactDetails.domesticCntryCode,
            $scope.contactDetails.intCntryCode
        );
        if (!validaityOfForm.validForm) {
            $scope.accountSetupForm.$valid = false;

            $scope.phoneNum_error = validaityOfForm.errorCode;
        }

        const registePostData = {
            username: $scope.userInfo.username,
            password: $scope.userInfo.password,
            flowName: $rootScope.flowName,
            contactInfo: contactPostData
        };

        if ($scope.accountSetupForm.$valid) {
            $scope.showSpinner = true;
            setTimeout(function () {
                npdiFactory.addNPDIppt
                    .query(registePostData)
                    .$promise.then(onRegisterUserSuccess, onRegisterUserFailure);
            }, 10);
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue
            // Manually set the $dirty flag for each input in the form to trigger validation

            const inputs = ["email", "phoneNumber", "username", "password", "confirmPassword"];

            $scope.forceValidations(inputs);
        }
    };

    function onRegisterUserSuccess(registeResponse) {
        if (registeResponse !== null && registeResponse.status === "successful") {
            redirectService.redirect(registeResponse, "ALL");
        } else {
            $scope.saveStatus = registeResponse.status;
        }
        $scope.showSpinner = false;
        deferred.resolve();
    }

    function onRegisterUserFailure(error) {
        if (error.headers("exception") !== null || error.headers("exceptionMessage") !== null) {
            $scope.saveStatus = error.data.error.code;
            $scope.saveStatusParams = error.data.error.errors[0];

            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                event_action: "npdi - onRegisterUserFailure",
                event_properties: {
                    login_error_code: error?.data?.error?.code,
                    error_message: error?.data?.error?.errors[0]
                }
            });
        } else {
            if (error.data.data.userName !== null) {
                $scope.usernameError = error.data.data.userName;
            }
            if (error.data.data.password !== null) {
                $scope.passwordError = error.data.data.password;
            }
        }
        $scope.showSpinner = false;
        deferred.reject(error);
    }

    $scope.npdiValidatePPTData = function () {
        if ($scope.npdiForm.$valid) {
            $scope.dispatchStateAgencyEvent($scope.stateAgency.value);
            $scope.showSpinner = true;
            if (!$scope.thisScope.selectedDivCodesValue.divisionCode) {
                $scope.selectedDivCodesValue = $scope.divisionCodesOptions;
                $scope.selectedDivCodesValue.divisionCodes = "";
            }
            const npdiFormData = {
                firstName: $scope.personalInfo.firstName,
                middleName: $scope.personalInfo.middleName,
                lastName: $scope.personalInfo.lastName,
                dateOfBirth: $scope.personalInfo.dateOfBirth,
                gender: $scope.selectedGenderValue,
                ssn: newSSN,
                maritalStatus: $scope.selectedMaritalStatusValue.code,

                currentAnnualIncome: $scope.employmentInfo.currentAnnualIncome,
                dateOfHire: $scope.employmentInfo.dateOfHire,

                divisionCodes: $scope.thisScope.selectedDivCodesValue.divisionCode,

                addressLine1: $scope.mailingAddress.addressLine1,
                addressLine2: $scope.mailingAddress.addressLine2,
                city: $scope.mailingAddress.city,
                zipcode: $scope.mailingAddress.zipcode,

                state: $scope.selectedStateValue.code,
                country: $scope.selectedCountryValue.code,
                flowName: "npdiAccountSetupFlow"
            };

            if ($scope.showStateAgency || $scope.showCTPEC) {
                if ($scope.stateAgency.value !== "N" || !$scope.stateAgency.value) {
                    npdiFormData.payGroupId = $scope.ctPec.payGroupId;
                    npdiFormData.employeeId = $scope.ctPec.employeeId;
                }
            }

            npdiFactory.validatePPTDataWithNPDI.query(
                npdiFormData,
                function (data) {
                    $scope.showSpinner = false;
                    console.log("PptDataWithNpdiValidated" + data);
                    if (data.errors === false) {
                        redirectService.redirect(data, "ALL");
                    }
                },
                //if data.errors===true, it will go with the function below.

                function (response) {
                    $scope.showSpinner = false;
                    $scope.autherizationMessage = "";
                    /*$scope.ssnError = '';
                    $scope.zipCodeError = '';
                    $scope.lastNameError = '';
                    $scope.dateOfBirthError = '';
                    $scope.streetAddressError = '';*/

                    if (response.headers("exception") !== null) {
                        $scope.showSpinner = false;
                        $scope.autherizationMessage = response.data.error.code;
                        $scope.autherizationMessageParams = response.data.error.errors[0];

                        eventBus.dispatchAmplitude({
                            event_type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                            event_action: "npdi - validatePPTDataWithNPDI.query",
                            event_properties: {
                                login_error_code: response?.data?.error?.code,
                                error_message: response?.data?.error?.errors[0]
                            }
                        });
                        $state.go("npdiError");
                    } else {
                        if (
                            response.message !== null &&
                            response.message === "Unexpected token T"
                        ) {
                            $scope.autherizationMessage = "Internal System Error";
                        } else if (response.data !== null || response.data.errors === true) {
                            //console.log("Error Data"    +   error.data) ;
                            /*$scope.ssnError = response.data.ssn;
                            $scope.zipCodeError = response.data.zipcode;
                            $scope.lastNameError = response.data.lastName;
                            $scope.dateOfBirthError = response.data.dateOfBirth;
                            $scope.streetAddressError = response.data.streetAddress;*/
                        } else if (response.appleunauthorized !== null) {
                            $state.go("login");
                        }
                    }
                }
            );
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue

            // Manually set the $dirty flag for each input in the form to trigger validation
            $scope.showSpinner = false;
            $scope.npdiForm.firstName.$dirty = true;
            $scope.npdiForm.middleName.$dirty = true;
            $scope.npdiForm.lastName.$dirty = true;
            $scope.npdiForm.gender.$dirty = true;
            $scope.npdiForm.ssn.$dirty = true;
            $scope.npdiForm.maritalStatus.$dirty = true;
            $scope.npdiForm.currentAnnualIncome.$dirty = true;
            $scope.npdiForm.dateOfHire.$dirty = true;
            $scope.npdiForm.dateOfBirth.$dirty = true;
            $scope.npdiForm.addressLine1.$dirty = true;
            $scope.npdiForm.addressLine2.$dirty = true;
            $scope.npdiForm.city.$dirty = true;
            $scope.npdiForm.zipcode.$dirty = true;
            $scope.npdiForm.state.$dirty = true;
            $scope.npdiForm.country.$dirty = true;

            if ($scope.showLawOfficer) {
                $scope.npdiForm.lawOfficer.$dirty = true;
            }

            if ($scope.npdiForm.divisionCodes) {
                $scope.npdiForm.divisionCodes.$dirty = true;
            }

            if ($scope.showCTPEC || $scope.showStateAgency) {
                if ($scope.stateAgency.value === "" && $scope.npdiForm.stateAgency) {
                    $scope.npdiForm.stateAgency.$dirty = true;
                }

                $scope.npdiForm.payGroupId.$dirty = true;
                $scope.npdiForm.employeeId.$dirty = true;

                if ($scope.stateAgency.value === "N") {
                    $scope.npdiForm.employerName.$dirty = true;
                } else {
                    $scope.npdiForm.employeeRCD.$dirty = true;
                }
            }
        }
    };
};

npdiRegistrationController.$inject = [
    "$q",
    "$rootScope",
    "$scope",
    "$state",
    "$timeout",
    "$window",
    "PersonalContactInfoCustomValidator",
    "PersonalContactInfoUpdateUtil",
    "npdiFactory",
    "redirectService",
    "eventBus"
];
export default npdiRegistrationController;
