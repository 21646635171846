import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import AccountOpenEvents from "./events/AccountOpenEvents";

const accountOpenController = function (
    $scope,
    $state,
    $modal,
    $uibModal,
    otsRegistrationService,
    preAccountOpeningFactory,
    otsAccountOpenFactory,
    $q,
    redirectService,
    $rootScope,
    $window,
    eventBus
) {
    const vm = this;
    vm.isDataLoaded = false;
    vm.countryCodes = [];
    $scope.stateCodes = [];
    $scope.isRegistered = null;
    $scope.loading = false;
    $scope.formData;
    vm.serviceError = false;
    const defaultFormData = {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        ssn: "",
        dateOfBirth: "",
        phoneNumber: ""
    };

    $scope.skipExperian = new URLSearchParams(window.location.href).get("skipExperian");
    $scope.hasReferrerParam = new URLSearchParams(window.location.search).get("referrerguid") || "";

    $scope.init = function () {
        const deferred = $q.defer();

        if ($window.history.state !== null && $window.history.state.fromForm) {
            $scope.formData = otsRegistrationService.getFormData();
        } else {
            $scope.formData = defaultFormData;
        }

        preAccountOpeningFactory.retrieveCountryCode.query({}).$promise.then(
            function (data) {
                if (data !== null) {
                    vm.countryCodes = data.filter((c) => c.code == "US");
                }
                deferred.resolve();
            },
            function () {
                deferred.reject();
            }
        );
        preAccountOpeningFactory.retrieveStateCode.query({}).$promise.then(
            function (data) {
                if (data !== null) {
                    $scope.stateCodes = data.filter((codes) => {
                        return ![
                            "AB",
                            "BC",
                            "BU",
                            "FM",
                            "LB",
                            "MB",
                            "MP",
                            "NB",
                            "NF",
                            "NL",
                            "NS",
                            "NT",
                            "NU",
                            "ON",
                            "PE",
                            "PW",
                            "QC",
                            "SK",
                            "WB",
                            "YT"
                        ].includes(codes.code);
                    });
                }
                deferred.resolve();
            },
            function () {
                deferred.reject();
            }
        );
        return deferred.promise;
    };

    $scope.$on("$locationChangeStart", function (event, current, previous) {
        if (previous.includes("accountOpenDetails")) {
            $window.history.replaceState({ fromForm: true }, "");
        }
    });

    function onInitSuccess() {
        vm.isDataLoaded = true;
    }

    function onInitFailure() {
        vm.isDataLoaded = true;
        vm.serviceError = true;
    }

    $scope.handlePersonalDetailsFormSubmit = function (data) {
        otsRegistrationService.setFormData(data);
        handleRoutingOption();
        eventBus.dispatch(AccountOpenEvents.PRE_ENROLL_FORM_1_NEXT);

        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON, // prettier-ignore
            event_properties: {
                // prettier-ignore
                selection: AccountOpenEvents.PRE_ENROLL_FORM_1_NEXT
            }
        });
        $scope.formData = defaultFormData;
    };

    $scope.handleDetailsFormSubmit = function (data) {
        otsRegistrationService.setFormData(data);
        $scope.formData = otsRegistrationService.getFormData();
        $state.go("otsAccountRegistrationB");
        eventBus.dispatch(AccountOpenEvents.PRE_ENROLL_FORM_2_NEXT);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON, // prettier-ignore
            event_properties: {
                // prettier-ignore
                selection: AccountOpenEvents.PRE_ENROLL_FORM_2_NEXT
            }
        });
    };

    const userRegistrationInformation = function () {
        const {
            ssn,
            firstName,
            lastName,
            addressLine1,
            addressLine2,
            city,
            state,
            zipCode,
            dateOfBirth
        } = otsRegistrationService.getFormData();

        const formatDOB = dateOfBirth.split("/").join("");
        const formatSSN = ssn.split("-").join("");

        const userInfo = {
            ssn: formatSSN,
            zipCode: zipCode,
            lastName: lastName,
            dateOfBirth: formatDOB,
            firstName: firstName,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateCode: state,
            country: "US"
        };

        return userInfo;
    };

    const handleRoutingOption = function () {
        let skipExperian = "";
        let referrerParam = "";

        if ($scope.skipExperian !== null) {
            skipExperian = `?skipExperian=${$scope.skipExperian}`;
        }
        if ($scope.hasReferrerParam) {
            referrerParam = `${skipExperian ? "&" : "?"}referrerGuid=${$scope.hasReferrerParam}`;
        }

        otsAccountOpenFactory
            .getExistingUserOTS(userRegistrationInformation(), skipExperian, referrerParam)
            .then(function (response) {
                if (response.errors === false) {
                    otsRegistrationService.setFormData({ context: response.context });
                    if (response.state === "ID_PROOFING_OTP_STATE") {
                        redirectService.redirect(response, "ALL", "OTS");
                    } else if (response.state === "REGISTER_USER") {
                        $scope.isRegistered = false;
                    } else if (response.state === "LOGIN_USER") {
                        $scope.isRegistered = true;
                    } else if (response.state === "OTS_ACCOUNT_SETUP") {
                        otsAccountOpenFactory
                            .createShadowAccountOTS()
                            .then((data) => redirectService.redirect(data, "ALL", "OTS"))
                            .catch(() => $state.go("idProofError", { errorCode: "idProofOTS" }));
                    }
                }
            })
            .catch(function (error) {
                console.log("An Error occured: " + error);
            });
    };

    $scope.init().then(onInitSuccess, onInitFailure);
};

accountOpenController.$inject = [
    "$scope",
    "$state",
    "$modal",
    "$uibModal",
    "otsRegistrationService",
    "preAccountOpeningFactory",
    "otsAccountOpenFactory",
    "$q",
    "redirectService",
    "$rootScope",
    "$window",
    "eventBus"
];
export default accountOpenController;
