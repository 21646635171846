const otsAccountRegistrationDetailsForm = `
<div class="col-md-6 col-md-offset-3">
  <pwr-ots-account-details-form
    state-codes="stateCodes"
    loaded="otsCtl.isDataLoaded"
    loading="otsCtl.loading"
    redirect="otsCtl.redirect"
    service-error="otsCtl.serviceError"
    form-data="formData"
    handle-details-form-submit="handleDetailsFormSubmit"
    has-referrer-param="hasReferrerParam"
  />
</div>
`;
export default otsAccountRegistrationDetailsForm;
