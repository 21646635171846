import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import oneIdEvents from "./events/OneIdEvents";

const LinkedConfirmationController = function (
    $scope,
    $location,
    $window,
    PrimaryPlanSelectionFactory,
    redirectService,
    eventBus,
    $translate,
    $cookies,
    SetACCUService,
    accountService,
    $state,
    profileFactory,
    LinkedConfirmationFactory
) {
    const vm = this;
    vm.loading = true;
    vm.error = false;

    profileFactory.retrieveUserNameForOneid.query(
        {},
        function (data) {
            vm.loading = false;
            vm.userName = data.data.userName;
        },

        function () {
            vm.loading = false;
        }
    );

    vm.continueClick = function () {
        vm.loading = true;

        eventBus.dispatch(oneIdEvents.CONFIRMATION_CONTD_CLICK, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: oneIdEvents.CONFIRMATION_CONTD_CLICK
            }
        });

        LinkedConfirmationFactory.retrieveNextDestination.query(
            {},
            function (data) {
                vm.loading = false;
                const dest = data.data.destinationUrl;
                if (dest !== "participant/#/choose-primaryplan") {
                    redirectService.redirect(data.data, "ALL");
                } else {
                    $state.go("choosePrimaryPlan", { oneIdFlow: "linking" });
                }
            },

            function () {
                vm.loading = false;
            }
        );

        $state.go("choosePrimaryPlan", { oneIdFlow: "linking" });
        //$state.go('mailPin', { optOut: 'otp-Expired' });
    };
};

LinkedConfirmationController.$inject = [
    "$scope",
    "$location",
    "$window",
    "PrimaryPlanSelectionFactory",
    "redirectService",
    "eventBus",
    "$translate",
    "$cookies",
    "SetACCUService",
    "accountService",
    "$state",
    "profileFactory",
    "LinkedConfirmationFactory",
    "DefaultCustomizationRetrService",
    "PreLoginAccuRetrievalService",
    "ReSetACCUService",
    "$q",
    "AccuCodeService",
    "$rootScope",
    "PreLoginContactInfoService"
];
export default LinkedConfirmationController;
